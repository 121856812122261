import { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import FormDialogElement from "./FormDialogElement";
import CustomTabs from "../Tabs";

const CreateNewElementDialog = ({
  prefetchedOptions,
  serviceType,
  createElementDialogData,
  open,
  onClose,
  onSubmit,
}) => {
  const [tabs] = useState(createElementDialogData.tabs);
  const renderTabContent = (content) => {
    return (
      <FormDialogElement
        prefetchedOptions={prefetchedOptions}
        serviceType={serviceType}
        elements={content.elements}
        onClose={onClose}
        onSubmit={onSubmit}
        buttonText={createElementDialogData.buttonText}
      />
    );
  };

  let header;
  if (serviceType === "securityGroup") {
    header = "Create New Security Group";
  } else if (serviceType === "userMaintenance") {
    header = "Create New User";
  } else if (serviceType === "clientMaintenance") {
    header = "Create New Client";
  } else if (serviceType === "terminal") {
    header = "Terminal";
  } else {
    header = "Create New Item";
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          width: "700px",
        },
      }}
    >
      <DialogTitle>{header}</DialogTitle>

      <DialogContent>
        <CustomTabs
          tabs={tabs.map((tab) => ({
            label: tab.label,
            component: renderTabContent(tab.content),
          }))}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewElementDialog;
