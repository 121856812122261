import { BASE_URL } from "../config";
import { Tenant as TenantModel } from "../models/Tenant";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getTenantAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`tenants/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getTenantsAPI = async () => {
  try {
    const response = await axiosInstance.get(`tenants/all`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const createTenant = async (newTenant) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/tenants/`,
      newTenant,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = new TenantModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Tenant created successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      "Failed to create tenant due to a network or parsing error.",
    );
  }
};
const updateTenant = async (updatedTenant) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/tenants/${updatedTenant.id}`,
      updatedTenant,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = new TenantModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message || "Tenant updated successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};


const uploadPaymentImgAPI = async (tenantId,Img) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/tenants/${tenantId}/payment_form_image`,{
        image_file:Img
      },{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data = new TenantModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message || "Tenant updated successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const deleteTenant = async (id) => {
  try {
    const response = await axiosInstance.delete(`${BASE_URL}/tenants/${id}`);
    return new StandardResponse(
      null,
      response.data.status_code,
      response.data.message || "Tenant deleted successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

export {
  getTenantAPI,
  getTenantsAPI,
  createTenant,
  updateTenant,
  deleteTenant,
  uploadPaymentImgAPI
};
