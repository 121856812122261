import { createPaymentRequestAPI } from "../api/PaymentRequestAPI";
import { getTenantAPI } from "../api/TenantAPI";

import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { visuallyHidden } from "@mui/utils";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { sendSMSAPI, sendEmailAPI } from "./../api/SMSEmailRequestsAPI";

import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import FormDialog from "./dialog/FormDialog";
import { v4 as uuidv4 } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import GeneralSelectMenu from "./buttons/GeneralSelectMenu";
import DataRightDrawer from "./drawer/DataRightDrawer";
import CreateNewElementDialog from "./form/CreateNewElementDialog";
import TerminalPaymentDialog from "./form/TerminalPaymentDialog";
import DeleteConfirmationDialog from "../components/form/DeleteConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import { getClientCredentialsAPI } from "../api/CredentialsAPI";
import { createSecurityGroup } from "../api/SecurityGroupsAPI";
import { createUser, deleteUser } from "../api/UsersAPI";
import { createTenant, deleteTenant } from "../api/TenantAPI";
import { getAuthCookies } from "../context/CookieManager";
import {
  createMenuItem,
  updateMenuItem,
  getMenuItems,
  deleteMenuItem,
} from "../api/MenuItemsAPI";
import { updateSecurityGroup } from "../api/SecurityGroupsAPI";
import { getClerks, getProspectInformation } from "../api/DockMasterAPI";
import {
  sendPaymentRequestAPI,
  updatePaymentRequest,
} from "../api/PaymentRequestAPI";
import { getCustomerInformation } from "../api/DockMasterAPI";
import {
  getSecurityGroups,
  deleteSecurityGroup,
} from "../api/SecurityGroupsAPI";
import { getTenantsAPI } from "../api/TenantAPI";
import { updateUser } from "../api/UsersAPI";

import { useNavigate } from "react-router-dom";
import { FRONTEND_URL } from "./../config";

import {
  getCurrentDateTime,
  generatePaymentRequestEmail,
  generatePaymentRequestSms,
  generateStaticPaymentRequestSms,
  generateStaticPaymentRequestEmail,
} from "../helpers/utils";
import TablePopOver from "./popOver/TablePopOver";
import MissingCustomerInfoDialog from "./dialog/MissingCustomerInfoDialog";
import BasicDatePicker from "./buttons/BasicDatePicker";

import { depositCode } from "../helpers/utils";

// The following functions are used to sort the table
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function GenerateTableFilters({ filterConfigs, applyFilters, resetFilters }) {
  const [localFilters, setLocalFilters] = useState({});

  const handleDateFilterChange = (filterName, value) => {
    // Used for Date type
    const formattedDate = value ? dayjs(value).format("YYYY-MM-DD") : null;
    setLocalFilters((prev) => ({ ...prev, [filterName]: formattedDate }));
  };

  const handleTextFilterChange = (filterName, event) => {
    // Used for Text type
    const value = event.target.value;
    setLocalFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSelectFilterChange = (filterName, value) => {
    // Used for Select type
    setLocalFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleApplyFilters = () => {
    applyFilters(localFilters);
  };

  const handleReset = () => {
    setLocalFilters({});
    resetFilters();
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* Filters Row */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {filterConfigs.map((config) => (
            <Box
              key={config.label}
              sx={{
                minWidth: 150,
                flexGrow: 1,
                margin: "0 8px",
                marginBottom: 2,
              }}
            >
              {config.type === "select" ? (
                <GeneralSelectMenu
                  label={config.label}
                  options={config.options}
                  value={localFilters[config.id] || ""}
                  onChange={(event) =>
                    handleSelectFilterChange(config.id, event)
                  }
                  fullWidth
                />
              ) : config.type === "date" ? (
                <BasicDatePicker
                  label={config.label}
                  value={null}
                  onChange={(newValue) =>
                    handleDateFilterChange(config.id, newValue)
                  }
                />
              ) : (
                <TextField
                  fullWidth
                  label={config.label}
                  value={localFilters[config.id] || ""}
                  onChange={(event) => handleTextFilterChange(config.id, event)}
                />
              )}
            </Box>
          ))}
        </Box>
        {/* Buttons Row */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{ mx: 1 }}
          >
            Apply Filters
          </Button>
          <Button variant="outlined" onClick={handleReset} sx={{ mx: 1 }}>
            Reset Filters
          </Button>
        </Box>
      </Toolbar>
    </Box>
  );
}

// Generate the table header
function GenerateTableHead(props) {
  const { tableHeaders, onRequestSort, order, orderBy } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#EAECF0" }} key={"header"}>
        {tableHeaders.map((headCell) => (
          <Tooltip title={headCell.description} key={headCell.id}>
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                fontFamily: "Hanken Grotesk",
                padding: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#667085",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </Tooltip>
        ))}
        <TableCell
          key={"Delete"}
          align="left"
          padding={"normal"}
          sx={{
            fontFamily: "Hanken Grotesk",
            padding: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#667085",
          }}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

// Generate the table toolbar
function GenerateTableToolbar(props) {
  const {
    numSelected,
    setSearchTerm,
    createElementDialogData,
    onAddButtonClick,
    serviceType,
  } = props;

  return (
    <Toolbar
      sx={{
        width: "100%",
        mb: 2.5,
        pl: { xs: 0, sm: 0 },
        pr: { xs: 1, sm: 1 },
        display: "flex",
        justifyContent: "space-between",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", pl: { xs: 3.5 } }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <TextField
          id="outlined-search"
          label="Search"
          type="search"
          variant="outlined"
          size="small"
          sx={{ fontFamily: "Hanken Grotesk" }}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      <Box sx={{ display: "flex", gap: 1 }}>
        {createElementDialogData &&
          (serviceType !== "terminal" ? (
            <Tooltip title="Create">
              <IconButton onClick={onAddButtonClick}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          ))}
      </Box>
    </Toolbar>
  );
}

// Generate the table
export default function GeneralTable({
  data,
  tableHeaders,
  itemID,
  filterConfigs,
  generateToolbar,
  generateRightDrawer,
  TerminalPaymentDialogContent,
  createElementDialogData,
  serviceType,
  dataPerPage,
  hideFooter = false,
  deleteButton = true,
  editButton = false,
  onFilterChange,
  applyFilters,
  resetFilters,
  DefaultPayLoc,
  type,
  tenant_security_group,
}) {
  // data: array of objects to display in the table
  // tableHeaders: represents the columns of the table (i.e: the variable names of the objects in the data array)
  // itemID: the variable name of the unique identifier of the objects in the data array
  // filterConfigs: array of objects that represent the filter configurations
  // generateToolbar: boolean to determine if the toolbar should be generated
  // generateRightDrawer: boolean to determine if the right drawer should be generated
  // createElementDialogData: object that represents the dialog data for creating a new element
  // TerminalPaymentDialogContent: object that represents the dialog data for creating a new terminal payment
  // serviceType: string that represents the type of service (i.e: securityGroup, userMaintenance, clientMaintenance, terminal)
  // dataPerPage: number of rows to display per page
  // hideFooter: boolean to determine if the footer should be hidden
  // deleteButton: boolean to determine if the delete button should be displayed

  const [searchTerm, setSearchTerm] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [updatedCreateElementDialogData, setUpdatedCreateElementDialogData] =
    useState(createElementDialogData);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(dataPerPage ?? 10);
  const [filters, setFilters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const user_id = getAuthCookies("tenantId");
  const [formDialogState, setFormDialogState] = React.useState({
    open: false,
    type: "",
    customerId: null,
    fullName: "",
    existingData: "",
  });
  const [missingDataFormDialogState, setMissingDataFormDialogState] =
    React.useState({
      open: false,
      phoneNumber: "",
      email: "",
    });

  // Used with terminal for fetching the select options
  const [loading, setLoading] = useState(false);
  const [disabledPopOver, setDisabledPopOver] = useState(true);
  const [disabledSave, setDisabledSave] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [clerksDialogOpen, setClerksDialogOpen] = useState(false);
  const [tenantsDialogOpen, setTenantsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const selectClerkOption = {
    tabs: [
      {
        label: "Select Clerk",
        content: {
          elements: [
            {
              type: "select",
              name: "clerks",
              label: "Clerk",
              includeAllOption: false,
              fullWidth: true,
            },
          ],
        },
      },
    ],
  };
  const selectTenantOption = {
    tabs: [
      {
        label: "Select Tenant",
        content: {
          elements: [
            {
              type: "select",
              name: "tenants",
              label: "Tenant",
              includeAllOption: false,
              fullWidth: true,
            },
          ],
        },
      },
    ],
  };

  const [createTerminalDialogOpen, setCreateTerminalDialogOpen] =
    useState(false);
  const [fetchedOptions, setFetchedOptions] = useState({});

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [PaymentRequestUUID, setPaymentRequestUUID] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowData, setRowData] = React.useState(null);

  const [shouldRefresh, setShouldRefresh] = useState(false);
  useEffect(() => {
    if (shouldRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [shouldRefresh]);

  const handleClickPopOver = (event, row) => {
    setRowData(row);
    if (!event) {
      setAnchorEl(null);
    } else setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const fetchDataAndOpenDialog = async (customerId) => {
    try {
      setMissingDataFormDialogState({
        open: false,
        phoneNumber: "",
        email: "",
      });
      let data = null;
      if (type === "customers") {
        data = await getCustomerInformation(user_id, customerId);
        setCustomerInfo(data.data[0]);
      } else {
        const prospectData = await getProspectInformation(user_id, customerId);
        data = await getCustomerInformation(
          user_id,
          prospectData.data[0].customerId,
        );
        setCustomerInfo(data.data[0]);
      }
      if (
        data.data[0].phone == null ||
        data.data[0].phone == "" ||
        data.data[0].email == null ||
        data.data[0].email == ""
      ) {
        setMissingDataFormDialogState({
          open: true,
          phoneNumber: data.data[0].phone,
          email: data.data[0].email,
        });
        return;
      } else {
        setCreateTerminalDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch customer information:", error);
    }
  };

  const openPaymentLink = () => {
    if (serviceType === "paymentRequest")
      window.open(`${FRONTEND_URL}otp?id=${rowData.id}`, "_blank");
    else {
      if (type === "customers") {
        window.open(
          `/pay/${rowData.id ?? rowData.customerID}/${user_id}`,
          "_blank",
        );
      } else {
        window.open(`/pay/${rowData.customerId}/${user_id}`, "_blank");
      }
    }
  };

  const sendRequest = async (type, source) => {
    try {
      if (source === "dropdown") {
        if (serviceType === "paymentRequest") {
          setLoading(true);

          await sendPaymentRequestAPI(rowData.id, {
            send_type: type,
          });
          setSnackbarMessage("Successfully Sent");
          setSnackbarOpen(true);
        } else {
          const customer = await getCustomerInformation(
            user_id,
            rowData.id ?? rowData.customerID ?? rowData.customerId,
          );
          if (type === "email") {
            handleClickPopOver(null);
            setFormDialogState({
              open: true,
              type: "email",
              customerId: customer.data[0].id,
              fullName:
                customer.data[0].firstName + " " + customer.data[0].lastName,
              existingData:
                customer.data[0].email === null || customer.data[0].email === ""
                  ? ""
                  : customer.data[0].email,
            });
          } else {
            if (
              customer.data[0].cellPhone === null ||
              customer.data[0].cellPhone === ""
            ) {
              handleClickPopOver(null);
              setFormDialogState({
                open: true,
                type: "sms",
                customerId: customer.data[0].id,
                fullName:
                  customer.data[0].firstName + " " + customer.data[0].lastName,
                existingData: "",
              });
            } else {
              sendTotalPayment(
                type,
                [customer.data[0].cellPhone],
                customer.data[0].id,
                customer.data[0].firstName + " " + customer.data[0].lastName,
              );
            }
          }
        }
      } else {
        setLoading(true);
        await sendPaymentRequestAPI(PaymentRequestUUID, {
          send_type: type,
        });
        setSnackbarMessage("Successfully Sent");
        setSnackbarOpen(true);
        setDisabledSave(false);
        setDisabledPopOver(true);
        setCreateTerminalDialogOpen(false);
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      handleClickPopOver(null);
      setLoading(false);
    }
  };

  const sendTotalPayment = async (
    type,
    data,
    customerId,
    fullName,
    subject,
  ) => {
    try {
      if (type === "email") {
        await sendEmailAPI(
          data,
          subject,
          generateStaticPaymentRequestEmail(
            fullName,
            `${process.env.REACT_APP_FRONT_END_URL}otp?customer-id=${customerId}&tenant-id=${user_id}`,
          ),
        );
      } else {
        await sendSMSAPI(
          generateStaticPaymentRequestSms(
            fullName,
            `${process.env.REACT_APP_FRONT_END_URL}otp?customer-id=${customerId}&tenant-id=${user_id}`,
          ),
          data,
        );
      }

      setSnackbarMessage("Successfully Sent");
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const filterDataInvoice = (type, data, id) => {
    if (type === "SI") {
      return data.filter((invoice) => invoice.id === id);
    } else if (type === "WI") {
      return data.filter((invoice) => invoice.id === id);
    } else return data;
  };

  // Terminal: Submit Create Form
  const handlePaymentDialog = async (formData) => {
    try {
      setLoading(true);
      setSnackbarOpen(false);
      let totalAmount = 0;
      let IsDeposit = false;
      const {
        customer,
        location = '',
        allInvoices = [],
        depositAmount = 0,
        depositType = '',
        ...formObjects
      } = formData;

      if (depositAmount && depositAmount > 0) {
        // If it's a deposit, the total is the deposit amount
        IsDeposit = true;
        totalAmount = depositAmount;
      } else if (location === "all" && depositType === 'all') {
        // Sum the amounts of all invoices
        totalAmount = allInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
      } else if (depositType === 'WI') {
        totalAmount = allInvoices.filter(invoice => formData.customerInvoices.includes(invoice.id))
        .reduce((sum, invoice) => sum + invoice.amount, 0);
      } else if (depositType === 'SI'){
        totalAmount = allInvoices.filter(invoice => formData.specificInvoice.includes(invoice.id))
        .reduce((sum, invoice) => sum + invoice.amount, 0);
      } else {
        // Sum the amounts of invoices that belong to the specified location
        totalAmount = allInvoices.filter(invoice => invoice.locationCode === location)
                                 .reduce((sum, invoice) => sum + invoice.amount, 0);
      }
  
      const paymentRequestUUID = uuidv4();
      setPaymentRequestUUID(paymentRequestUUID);

      const tenantId = getAuthCookies("tenantId");
      const tenant = await getTenantAPI(tenantId);
      const clientCredentials = await getClientCredentialsAPI(tenantId);
      if (!clientCredentials) {
        throw new Error("Failed to fetch client credentials");
      }

      console.log("formData", formData);
      const body = {
        id: paymentRequestUUID,
        client_id: tenantId,
        requesttype: "",
        firstname: customer.firstName,
        lastname: customer.lastName,
        address: customer.address1,
        city: customer.city,
        state: customer.state,
        zip: customer.zip,
        country: "",
        primaryemail: customer?.email || missingDataFormDialogState.email,
        cellphonenumber:
          customer?.phone || missingDataFormDialogState.phoneNumber,
        message: generatePaymentRequestSms(
          `${customer.firstName} ${customer.lastName}`,
          `${totalAmount}`,
          `${FRONTEND_URL}otp?id=${paymentRequestUUID}`,
        ),
        htmlbody: generatePaymentRequestEmail(
          `${customer.firstName} ${customer.lastName}`,
          `${totalAmount}`,
          `${FRONTEND_URL}otp?id=${paymentRequestUUID}`,
        ),
        depositamount: IsDeposit ? depositAmount : 0,
        deposittype: depositType,
        totalamount: totalAmount,
        Invoices: IsDeposit
          ? null
          : filterDataInvoice(
              formData.depositType,
              formData?.allInvoices,
              formData?.customerInvoices ?? formData?.specificInvoice,
            ),
        locationid: location,
        datesent: getCurrentDateTime(),
        created_at: getCurrentDateTime(),
        updated_at: getCurrentDateTime(),
        customerId: customer.id,
        additional_data: {
          ...formObjects,
        },
        tenant_info: {
          ...tenant,
          merchantId: clientCredentials[0].VpMerchantId,
          submerchantId: clientCredentials[0].VpSubMerchantId,
        },
      };
      await createPaymentRequestAPI(body);

      setSnackbarMessage("Payment request created successfully");
      setDisabledSave(true);
      setDisabledPopOver(false);
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  // Create element
  const fetchCreateDialogData = async () => {
    setLoading(true);
    try {
      let options = {};
      if (serviceType === "securityGroup") {
        const menuItemsData = await getMenuItems();
        options.menuitems = menuItemsData.data.map((item) => ({
          value: item.id,
          label: item.itemname,
          description: item.itemdescription,
        }));
      } else if (serviceType === "userMaintenance") {
        const tenantsData = await getTenantsAPI();
        options.tenants = tenantsData.map((tenant) => ({
          value: tenant.ClientID,
          label: `${tenant.ClientID} - ${tenant.ClientName}`,
        }));
      }
      setFetchedOptions(options);

      if (serviceType === "userMaintenance") {
        setTenantsDialogOpen(true);
      } else {
        setCreateDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch dialog data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddButtonClick = () => {
    if (serviceType == "clientMaintenance") {
      navigate("/admin/client-maintenance/create");
    } else {
      fetchCreateDialogData();
    }
  };
  const handleTenantSubmit = async (selectedTenantId) => {
    setLoading(true);
    setTenantId(selectedTenantId.tenants);
    setTenantsDialogOpen(false);
    try {
      let options = {};

      const securityGroupsData = await getSecurityGroups();
      const groupsWithoutAdmin = securityGroupsData.data
        .filter((data) => data.id === "Administration")
        .map((data) => ({
          value: data.id,
          label: data.name,
        }));
      switch (tenant_security_group) {
        case "Client":
          options.securitygroups = groupsWithoutAdmin;
          break;
        case "Administration":
          options.securitygroups = securityGroupsData.data.map((data) => ({
            value: data.id,
            label: data.name,
          }));
          break;
        case "Client Maintenance":
          options.securitygroups = groupsWithoutAdmin;
          break;
        case "User Maintenance":
          options.securitygroups = groupsWithoutAdmin;
          break;

        default:
          break;
      }

      const clerksData = await getClerks(selectedTenantId.tenants);
      options.clerks = clerksData.data.map((clerk) => ({
        id: clerk.id,
        value: clerk.id,
        label: clerk.name,
        name: clerk.name,
        emailAddress: clerk.emailAddress,
        groupEmailAddress: clerk.groupEmailAddress,
      }));
      setFetchedOptions(options);
      setClerksDialogOpen(true);
    } catch (error) {
      console.error("Failed to fetch clerks data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClerkDialog = () => {
    setClerksDialogOpen(false);
  };
  const handleCloseTenantDialog = () => {
    setTenantsDialogOpen(false);
  };
  const handleClerkSubmit = async (clerkID) => {
    const clerk = fetchedOptions.clerks.find(
      (clerk) => clerk.id === clerkID.clerks,
    );
    setClerksDialogOpen(false);
    const updatedCreateElementDialogData = {
      ...createElementDialogData,
      tabs: createElementDialogData.tabs.map((tab) => ({
        ...tab,
        content: {
          ...tab.content,
          elements: tab.content.elements
            .filter((data) => data.name != "tenant_id")
            .map((element) => {
              if (element.name === "email") {
                return { ...element, value: clerk.emailAddress };
              } else if (element.name === "name") {
                return { ...element, value: clerk.name };
              }
              return element;
            }),
        },
      })),
    };

    setUpdatedCreateElementDialogData(updatedCreateElementDialogData);
    setCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };
  const handleCreateSubmit = async (formData) => {
    // Create Element API
    handleCloseCreateDialog();
    let response;
    setLoading(true);
    if (serviceType === "securityGroup") {
      response = await createSecurityGroup(formData);
    } else if (serviceType === "userMaintenance") {
      response = await createUser({ tenant_id: tenantId, ...formData });
    } else if (serviceType === "clientMaintenance") {
      response = await createTenant(formData);
    } else if (serviceType === "clientMenuItemMaintenance") {
      response = await createMenuItem(formData);
    }
    setLoading(false);
    setSnackbarMessage(response.message);
    setSnackbarOpen(true);
    setShouldRefresh(true);
  };

  // Update element API
  const handleUpdateSubmit = async (formData) => {
    setIsDrawerOpen(false);
    let response;
    setLoading(true);
    if (serviceType === "userMaintenance") {
      response = await updateUser(formData.id, formData);
    } else if (serviceType === "clientMenuItemMaintenance") {
      response = await updateMenuItem(formData);
    } else if (serviceType === "paymentRequest") {
      response = await updatePaymentRequest(formData);
      setIsUpdateDrawerOpen(false);
    } else if (serviceType === "securityGroup") {
      response = await updateSecurityGroup(formData);
    }
    setLoading(false);
    setSnackbarMessage(response.message);
    setSnackbarOpen(true);
    setShouldRefresh(true);
  };

  const [updateButtonElement, setUpdateButtonElement] = useState(false);
  const updatePayReqElements = {
    header: "Payment Request",
    buttonText: "Update",
    serviceType: serviceType,
    tabs: [
      {
        label: "Information",
        data: [
          { type: "text", id: "id", label: "ID", disabled: true },
          {
            type: "select",
            id: "paymentstatus",
            label: "Payment Status",
            name: "paymentstatus",
            includeAllOption: false,
          },
        ],
      },
    ],
  };
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);
  const handleUpdateButton = async (event, id) => {
    setUpdateButtonElement(true);

    setIsUpdateDrawerOpen(true);
    event.stopPropagation();
    const rowData = data.find((row) => row[itemID] === id);
    setSelectedRowData(rowData);
  };

  // Delete element API
  const handleConfirmDelete = async (rowData) => {
    const id = rowData[itemID];
    try {
      setLoading(true);
      let response;
      if (serviceType === "securityGroup") {
        response = await deleteSecurityGroup(id);
      } else if (serviceType === "userMaintenance") {
        response = await deleteUser(id);
      } else if (serviceType === "clientMaintenance") {
        response = await deleteTenant(id);
      } else if (serviceType === "clientMenuItemMaintenance") {
        response = await deleteMenuItem(id);
      }
      setLoading(false);
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
      setShouldRefresh(true);
    } catch (error) {
      console.error("Failed to delete item:", error);
      setSnackbarMessage("Failed to delete item");
      setSnackbarOpen(true);
    }
  };
  const handleDelete = (event, id) => {
    event.stopPropagation();
    const rowData = data.find((row) => row[itemID] === id);
    setSelectedRowData(rowData);
    setDeleteDialogOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Open Data Right Drawer or Create Dialog (for terminal)
  const handleClick = (event, id) => {
    event.stopPropagation();
    const rowData = data.find((row) => row[itemID] === id);
    setSelectedRowData(rowData);

    if (
      tenant_security_group === "Client" &&
      rowData.securitygroup === "Administration"
    ) {
      setSnackbarMessage("Client cannot modify Administration users.");
      setSnackbarOpen(true);
      return;
    }

    if (serviceType === "terminal") {
      handleClickPopOver(null);
      setDisabledSave(false);
      setDisabledPopOver(true);
      setPaymentRequestUUID(null);
      fetchDataAndOpenDialog(id);
    } else if (serviceType === "clientMaintenance") {
      navigate(`/admin/client-maintenance/${id}`);
    } else {
      setIsDrawerOpen(true);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }
    let filteredData = searchTerm
      ? data.filter((row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        )
      : data;
    Object.entries(filters).forEach(([filterName, filterValue]) => {
      if (filterValue) {
        filteredData = filteredData.filter((row) => {
          if (typeof filterValue === "string") {
            return (
              String(row[filterName]).toLowerCase() ===
              filterValue.toLowerCase()
            );
          } else {
            return row[filterName] === filterValue;
          }
        });
      }
    });

    filteredData = filteredData?.filter((row) => {
      const rowDate = dayjs(row.date_time);
      let isValid = true;

      if (filters.startDate) {
        const startDate = dayjs(filters.startDate);
        isValid =
          rowDate.isSame(startDate, "day") || rowDate.isAfter(startDate);
      }

      if (filters.endDate) {
        const endDate = dayjs(filters.endDate);
        isValid = rowDate.isSame(endDate, "day") || rowDate.isBefore(endDate);
      }

      return isValid;
    });

    return stableSort(filteredData, getComparator(order, orderBy))?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [order, orderBy, page, rowsPerPage, data, searchTerm, filters]);

  return (
    <>
      <Box sx={{ width: { xs: "100%", sm: "100%" }, mx: "auto" }}>
        {/* Table Filters */}
        {filterConfigs && filterConfigs.length > 0 && (
          <Paper sx={{ width: "100%", mb: 2, border: "1px solid #D0D5DD" }}>
            <TableContainer>
              <GenerateTableFilters
                filterConfigs={filterConfigs}
                onFilterChange={onFilterChange}
                applyFilters={applyFilters}
                resetFilters={resetFilters}
              />
            </TableContainer>
          </Paper>
        )}

        {/* Table Toolbar */}
        {generateToolbar && (
          <GenerateTableToolbar
            serviceType={serviceType}
            numSelected={0}
            setSearchTerm={setSearchTerm}
            createElementDialogData={updatedCreateElementDialogData}
            onAddButtonClick={handleAddButtonClick}
          />
        )}

        {/* Table */}
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2, border: "1px solid #D0D5DD" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <GenerateTableHead
                  tableHeaders={tableHeaders}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{
                          cursor: "pointer",
                          "& > *": { borderBottom: "unset" },
                        }}
                      >
                        {tableHeaders.map((headCell, indexHeader) => (
                          <TableCell
                            key={indexHeader + 1000}
                            align="left"
                            onClick={(event) => handleClick(event, row[itemID])}
                          >
                            {headCell.id === "totalamount" ||
                            headCell.id === "depositamount"
                              ? `$ ${Number(row[headCell.id] ?? 0).toFixed(2)}`
                              : row[headCell.id] != null
                              ? row[headCell.id].toString()
                              : "1"}
                          </TableCell>
                        ))}
                        <TableCell key={"Delete"} align="left">
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            {editButton && (
                              <Tooltip title="Update">
                                <IconButton
                                  onClick={(event) =>
                                    handleUpdateButton(event, row[itemID])
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {deleteButton &&
                              row[itemID] !== "Administration" &&
                              row[itemID] !== "Client" && (
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={(event) =>
                                      handleDelete(event, row[itemID])
                                    }
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {(serviceType === "terminal" ||
                              serviceType === "paymentRequest") && (
                              <>
                                <IconButton
                                  onClick={(e) => handleClickPopOver(e, row)}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePopOver
                anchorEl={anchorEl}
                tabs={[
                  {
                    label: "Open Payment Link",
                    icon: LanguageIcon,
                    onClick: openPaymentLink,
                  },
                  {
                    label: "Send Email Payment Link",
                    icon: MailOutlineIcon,
                    logo: <LanguageIcon sx={{ pr: 1 }} />,
                    onClick: () => sendRequest("email", "dropdown"),
                  },
                  {
                    label: "Send SMS Payment Link",
                    icon: PhoneIphoneIcon,
                    onClick: () => sendRequest("sms", "dropdown"),
                  },
                ]}
                handleClick={(e) => handleClickPopOver(e, null)}
              />

              {formDialogState.open && (
                <FormDialog
                  formDialogState={formDialogState}
                  handleOpen={() => {
                    setFormDialogState((prev) => ({
                      ...prev,
                      open: !prev.open,
                    }));
                  }}
                  submit={sendTotalPayment}
                />
              )}
              <MissingCustomerInfoDialog
                formDialogState={missingDataFormDialogState}
                handleOpen={() => {
                  setMissingDataFormDialogState((prev) => ({
                    ...prev,
                    open: !prev.open,
                  }));
                }}
                submit={(email, phone) => {
                  setMissingDataFormDialogState((prev) => ({
                    ...prev,
                    phoneNumber: phone,
                    email: email,
                  }));
                  setCreateTerminalDialogOpen(true);
                }}
              />
              {generateRightDrawer && (
                <DataRightDrawer
                  generateRightDrawer={generateRightDrawer}
                  rowData={selectedRowData}
                  isOpen={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  onSubmit={handleUpdateSubmit}
                  tenant_security_group={tenant_security_group}
                />
              )}
              {updateButtonElement && (
                <DataRightDrawer
                  generateRightDrawer={updatePayReqElements}
                  rowData={selectedRowData}
                  isOpen={isUpdateDrawerOpen}
                  onClose={() => setIsUpdateDrawerOpen(false)}
                  onSubmit={handleUpdateSubmit}
                  updatePayReq={true}
                />
              )}
              {/* Used in Terminal for payment */}
              {createTerminalDialogOpen && (
                <TerminalPaymentDialog
                  customerInfo={customerInfo}
                  serviceType={serviceType}
                  createElementDialogData={TerminalPaymentDialogContent}
                  DefaultPayLoc={DefaultPayLoc}
                  open={createTerminalDialogOpen}
                  rowData={selectedRowData}
                  onClose={() => setCreateTerminalDialogOpen(false)}
                  onSubmit={handlePaymentDialog}
                  disabledSave={disabledSave}
                  disabledPopOver={disabledPopOver}
                  sendRequest={sendRequest}
                  PaymentRequestUUID={PaymentRequestUUID}
                />
              )}
            </TableContainer>
            {!hideFooter && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Box>
      </Box>
      {/* Loading screen, used in Terminal for CreateDialog */}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 999999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Create for all services */}
      {createDialogOpen && (
        <CreateNewElementDialog
          serviceType={serviceType}
          createElementDialogData={updatedCreateElementDialogData}
          prefetchedOptions={fetchedOptions}
          open={createDialogOpen}
          onClose={handleCloseCreateDialog}
          onSubmit={handleCreateSubmit}
        />
      )}
      {/* Used in User Maintenance */}
      {clerksDialogOpen && (
        <CreateNewElementDialog
          serviceType={serviceType}
          createElementDialogData={selectClerkOption}
          prefetchedOptions={fetchedOptions}
          open={clerksDialogOpen}
          onClose={handleCloseClerkDialog}
          onSubmit={handleClerkSubmit}
        />
      )}
      {tenantsDialogOpen && (
        <CreateNewElementDialog
          serviceType={serviceType}
          createElementDialogData={selectTenantOption}
          prefetchedOptions={fetchedOptions}
          open={tenantsDialogOpen}
          onClose={handleCloseTenantDialog}
          onSubmit={handleTenantSubmit}
        />
      )}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        itemID={itemID}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        rowData={selectedRowData}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
}
