import { React, useState, useEffect } from "react";
import GeneralTable from "../components/GeneralTable";
import { getUsersWithTenantID, getUsers } from "../api/UsersAPI";
import { CircularProgress, Box } from "@mui/material";
import { getTenantsAPI } from "../api/TenantAPI";
import { getAuthCookies } from "../context/CookieManager";

const tableHeaders = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
    description: "ID of the user",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    description: "Name of the user",
  },
  {
    id: "tenant_id",
    numeric: true,
    disablePadding: true,
    label: "Tenant ID",
    description: "Tenant ID of the user",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: true,
    label: "Email",
    description: "Email of the user",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    description: "Status of the user",
  },
  {
    id: "securitygroup",
    numeric: true,
    disablePadding: true,
    label: "Security Group",
    description: "Security Group of the user",
  },
  {
    id: "void_refund_permission",
    numeric: false,
    disablePadding: true,
    label: "Payment Void/Refund & Status change",
    description: "Payment status of the user",
  },
];

const generateRightDrawer = {
  buttonText: "Update",
  header: "User Maintenance",
  serviceType: "userMaintenance",
  tabs: [
    {
      label: "General Information",
      data: [
        { type: "text", id: "id", label: "ID" },
        { type: "text", id: "name", label: "Name" },
        { type: "text", id: "email", label: "Email" },
        { type: "text", id: "tenant_id", label: "Tenant ID", disabled: true },
        {
          type: "checkbox",
          id: "void_refund_permission",
          label: "Payment Void/Refund & Status change",
        },
        { type: "checkbox", id: "inactive", label: "Account Status: Inactive" },
        {
          type: "select",
          name: "securitygroup",
          id: "securitygroup",
          label: "Security Group",
          includeAllOption: false,
          fullWidth: true,
        },
      ],
    },
  ],
};

export default function UserMaintenance() {
  const [data, setData] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const generateToolbar = true;
  const serviceType = "userMaintenance";
  const itemID = "id";
  const tenant_security_group = getAuthCookies("securityGroup");
  const tenant_id = getAuthCookies("tenantId");

  const createElementDialogData = {
    tabs: [
      {
        label: "General Information",
        content: {
          elements: [
            {
              type: "text",
              name: "name",
              label: "Name",
              fullWidth: true,
              disabled: true,
            },
            {
              type: "text",
              name: "email",
              label: "Email",
              fullWidth: true,
              disabled: true,
            },
            {
              type: "text",
              name: "password",
              label: "Password",
              fullWidth: true,
            },
            {
              type: "switch",
              name: "inactive",
              label: "In Active",
              fullWidth: true,
            },
            {
              type: "select",
              name: "securitygroup",
              label: "Security Group",
              includeAllOption: false,
              fullWidth: true,
            },
            {
              type: "select",
              name: "tenant_id",
              label: "Tenant",
              options: tenants && Array.isArray(tenants) ? tenants.map((tenant) => ({
                label: tenant.ClientName,
                value: tenant.ClientID,
              })) : [], // Provide an empty array if tenants is undefined or not an array
              includeAllOption: false,
              fullWidth: true,
            },            
            {
              type: "switch",
              id: "void_refund_permission",
              name: "void_refund_permission",
              label: "Void/Refund Allowed",
            },
            {
              type: "select",
              name: "permissions",
              includeAllOption: true,
              label: "Permissions",
              fullWidth: true,
            },
          ],
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {//TODO: Implement logic in the backend to prevent a non-Administrator user from accessing the getUsers endpoints
        let usersResponse;
        if (tenant_security_group === "Administration") {
          // Assuming there's an API to fetch all users regardless of tenant
          usersResponse = await getUsers(); 
        } else {
          usersResponse = await getUsersWithTenantID(tenant_id);
        }
  
        setData(usersResponse.data);
        const jsonResponse = await getTenantsAPI();
        if (Array.isArray(jsonResponse)) {
          setTenants(jsonResponse);
        } else {
          console.error("Expected tenants data to be an array, received:", jsonResponse);
          setTenants([]); // Set to an empty array if data is not as expected
        }
        setTenants(jsonResponse); // Make sure to access the data property if the API returns it this way
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [tenant_security_group, tenant_id]); // Added dependencies to useEffect
  

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GeneralTable
      data={data}
      itemID={itemID}
      tableHeaders={tableHeaders}
      generateToolbar={generateToolbar}
      createElementDialogData={createElementDialogData}
      generateRightDrawer={generateRightDrawer}
      serviceType={serviceType}
      tenant_security_group={tenant_security_group}
    />
  );
}
