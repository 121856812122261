export class WorkOrder {
    constructor(data) {
      this.id = data.id || "";
      this.customer = data.customer || "";
      this.openDate = data.openDate || "";
      this.boat = data.boat || "";
      this.type = data.type || "";
      this.status = data.status || "";
      this.locationCode = data.locationCode || "";
    }
  }