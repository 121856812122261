import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

import { useState } from "react";
import { useEffect } from "react";

export default function FormDialog({ formDialogState, handleOpen, submit }) {
  const [submitForm, setSubmitForm] = useState({
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    setSubmitForm((prev) => ({
      ...prev,
      phoneNumber: formDialogState?.phoneNumber ?? "",
      email: formDialogState?.email ?? "",
    }));
  }, [formDialogState]);

  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    if (submitForm.email === "" || submitForm.phoneNumber === "") {
      setError(true);
      return;
    } else {
      submit(submitForm.email, submitForm.phoneNumber);
      handleOpen();
    }
  };

  return (
    <>
      <Dialog
        open={formDialogState.open}
        onClose={handleOpen}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
          },
        }}
      >
        <DialogTitle>Customer has missing details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the following fields to continue
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="email"
            label={"Email"}
            onChange={(e) =>
              setSubmitForm((prev) => ({ ...prev, email: e.target.value }))
            }
            fullWidth
            value={submitForm.email}
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            name="phoneNumber"
            label={"Phone Number"}
            onChange={(e) =>
              setSubmitForm((prev) => ({
                ...prev,
                phoneNumber: e.target.value,
              }))
            }
            type="number"
            fullWidth
            value={submitForm.phoneNumber}
            variant="standard"
          />
          <Box sx={{ color: "red", mt: 1 }}>
            {error && `Please fill the required fields`}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>Cancel</Button>
          <Button onClick={handleSubmit}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
