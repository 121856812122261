import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import GeneralSelectMenu from "../buttons/GeneralSelectMenu";
import GeneralMultiSelect from "../buttons/GeneralMultiSelect";

import GeneralControlButton from "../buttons/GeneralControlButton";
import GeneralRadioGroup from "../buttons/GeneralRadioGroup";
import GeneralCheckbox from "../buttons/GeneralCheckbox";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

const FormDialogElement = ({
  prefetchedOptions,
  serviceType,
  elements,
  onClose,
  onSubmit,
  buttonText,
}) => {
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const allElements = [...elements, ...additionalFields];
    return allElements.every((element) => {
      if (!element.required) return true;
      const value = formValues[element.name];
      return value !== undefined && value !== "";
    });
  };

  useEffect(() => {
    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  }, [formValues, elements, additionalFields]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (prefetchedOptions) {
          setOptions(prefetchedOptions);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };
    fetchData();
    setFormValues((prevValues) =>
      elements.reduce((acc, element) => {
        if (
          serviceType === "login" &&
          (element.type === "text" || element.type === "password")
        ) {
          acc[element.name] =
            prevValues[element.name] !== undefined
              ? prevValues[element.name]
              : element.defaultValue || element.value || "";
        } else {
          acc[element.name] =
            element.type !== "switch"
              ? element.defaultValue || element.value || ""
              : prevValues[element.name] !== undefined
              ? prevValues[element.name]
              : false;
        }
        return acc;
      }, {}),
    );
  }, [serviceType, elements, prefetchedOptions]);

  const handleChange = (name, value, type) => {
    if (type === "checkbox") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: !value,
      }));
    } else if (type === "multiSelect") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value.join(","),
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!checkFormValidity()) {
      console.error("Please fill out all required fields.");
      return;
    }
    onSubmit(formValues);
  };

  const renderElement = (element, index) => {
    let elementOptions = [];
    if (element.type === "select" || element.type === "multiSelect") {
      if (element.name === "location") {
        elementOptions = options.locations || [];
      } else if (element.name === "depositType") {
        elementOptions = options.depositTypes || [];
      } else if (element.name === "paymentstatus") {
        elementOptions = [
          { value: "PAID", label: "PAID" },
          { value: "UNPAID", label: "UNPAID" },
          { value: "FAILED", label: "FAILED" },
        ];
      } else if (element.name === "menuitems") {
        elementOptions = options.menuitems || [];
      } else if (element.name === "clerks") {
        elementOptions = options.clerks || [];
      } else if (element.name === "tenants") {
        elementOptions = options.tenants || [];
      } else if (element.name === "securitygroup") {
        elementOptions = options.securitygroups || [];
      } else {
        elementOptions = element.options || [];
      }
    } else {
      elementOptions = options;
    }
    switch (element.type) {
      case "text":
        return (
          <TextField
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            type="text"
            placeholder={element.placeholder}
            variant="outlined"
            fullWidth={element.fullWidth}
            required={element.required ? element.required : false}
            disabled={element.disabled || false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            multiline={element.multiline || false}
            rows={element.rows || 1}
            {...element.props}
          />
        );
      case "password":
        return (
          <TextField
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            type="password"
            placeholder={element.placeholder}
            variant="outlined"
            fullWidth={element.fullWidth}
            required={element.required ? element.required : false}
            disabled={element.disabled || false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            {...element.props}
          />
        );
      case "switch":
        return (
          <GeneralControlButton
            key={index}
            name={element.name}
            label={element.label}
            description={element.description}
            required={element.required ? element.required : false}
            onChange={(checked) =>
              handleChange(element.name, checked, "checkbox")
            }
            disabled={element.disabled || false}
            {...element.props}
          />
        );
      case "multiSelect":
        return (
          <Box sx={{ my: 1 }}>
            <GeneralMultiSelect
              key={index}
              defaultValue={element.value}
              name={element.name}
              label={element.label}
              options={elementOptions}
              includeAllOption={element.includeAllOption}
              required={element.required ? element.required : false}
              onChange={(name, value, type) => handleChange(name, value, type)}
              disabled={element.disabled || false}
              {...element.props}
            />
          </Box>
        );
      case "select":
        return (
          <Box sx={{ my: 1 }}>
            <GeneralSelectMenu
              key={index}
              defaultValue={element.value}
              name={element.name}
              label={element.label}
              options={elementOptions}
              includeAllOption={element.includeAllOption}
              required={element.required ? element.required : false}
              onChange={(value) => handleChange(element.name, value + "")}
              disabled={element.disabled || false}
              {...element.props}
            />
          </Box>
        );
      case "radio":
        return (
          <GeneralRadioGroup
            key={index}
            defaultValue={element.value}
            name={element.name}
            label={element.label}
            options={element.options}
            required={element.required ? element.required : false}
            disabled={element.disabled || false}
            onChange={(e) => handleChange(element.name, e.target.value)}
            {...element.props}
          />
        );
      case "checkbox":
        if (element.name == "status") {
          element.value = element.value == "Active" ? true : false;
        }
        return (
          <GeneralCheckbox
            name={element.name}
            label={element.label}
            required={element.required ? element.required : false}
            initialValue={element.value}
            disabled={element.disabled || false}
            onChange={(checked) => handleChange(element.name, checked)}
          />
        );
      case "button": // New case for button
        return (
          <PrimaryButton
            key={index}
            onClick={() => element.onClick && element.onClick()}
            variant={element.variant || "contained"}
            color={element.color || "primary"}
            disabled={element.disabled || false}
            {...element.props}
          >
            {element.label}
          </PrimaryButton>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { my: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {elements.map((element, index) => renderElement(element, index))}
        {additionalFields.map((element, index) =>
          renderElement(element, index + elements.length),
        )}
        <Box
          sx={{ display: "flex", justifyContent: "end", mt: 2, gap: "10px" }}
        >
          {onClose && (
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          )}
          <PrimaryButton type="submit" disabled={!isFormValid}>
            {buttonText || "Create"}
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
};

export default FormDialogElement;
