import { DepositType as DepositTypeModel } from "../models/DepositType";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getDepositType = async (id) => {
  try {
    const response = await axiosInstance.get(`deposit_types/${id}/`);
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new DepositTypeModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message,
      );
    } else {
      const errorMessage =
        jsonResponse.message ||
        "An error occurred while fetching the deposit type data.";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const getDepositTypes = async () => {
  try {
    const response = await axiosInstance.get(`deposit_types/`);
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = jsonResponse.data.map((item) => new DepositTypeModel(item));

      data.sort((a, b) => {
        const aEndsWithInvoice = a.description.endsWith("Invoice");
        const bEndsWithInvoice = b.description.endsWith("Invoice");

        if (aEndsWithInvoice && !bEndsWithInvoice) {
          return -1;
        } else if (!aEndsWithInvoice && bEndsWithInvoice) {
          return 1;
        }
        return a.description.localeCompare(b.description);
      });

      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message,
      );
    } else {
      const errorMessage =
        jsonResponse.message ||
        "An error occurred while fetching the deposit types data.";
      return new StandardResponse(
        [],
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const createDepositType = async (newDepositType) => {
  try {
    const response = await axiosInstance.post(
      `deposit_types/`,
      newDepositType,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new DepositTypeModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        "Deposit type created successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to create the deposit type";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "Failed to create deposit type due to a network or parsing error.",
    );
  }
};

const updateDepositType = async (id, updatedDepositType) => {
  try {
    const response = await axiosInstance.put(
      `deposit_types/${id}/`,
      updatedDepositType,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new DepositTypeModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message || "Deposit type updated successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to update the deposit type";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const deleteDepositType = async (id) => {
  try {
    const response = await axiosInstance.delete(`deposit_types/${id}/`);
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      return new StandardResponse(
        null,
        jsonResponse.status_code,
        jsonResponse.message || "Deposit type deleted successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to delete the deposit type";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

export {
  getDepositType,
  getDepositTypes,
  createDepositType,
  updateDepositType,
  deleteDepositType,
};
