import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

import { useState } from "react";

export default function ProfileDialog({ formDialogState, handleOpen, submit }) {
  const [value, setvalue] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    if (value === "") {
      setError(true);
      return;
    } else {
      submit(value);
      handleOpen();
    }
  };

  return (
    <>
      <Dialog
        open={formDialogState.open}
        onClose={handleOpen}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
          },
        }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            please enter new password to continue
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label={"Password"}
            onChange={(e) => setvalue(e.target.value)}
            fullWidth
            value={value}
            variant="standard"
          />
          <Box sx={{ color: "red", mt: 1 }}>
            {error && `Please enter a valid password`}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
