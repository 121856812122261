export class Payment {
  constructor(data) {
    this.id = data.id || "";
    this.payment_request_id = data.payment_request_id || "";
    this.client_id = data.client_id || 0;
    this.customer_id = data.customer_id || 0;
    this.batch_id = data.batch_id || "";
    this.amount = data.amount || 0;
    this.currency = data.currency || "";
    this.transaction_type = data.transaction_type || "";
    this.status = data.status || "";
    this.payment_method = data.payment_method || "";
    this.external_transaction_id = data.external_transaction_id || "";
    this.payment_date = data.payment_date || "";
    this.partial_payment = data.partial_payment || false;
    this.last_opened = data.last_opened || "";
    this.numberofopens = data.numberofopens || 0;
    this.recurring_billing_id = data.recurring_billing_id || 0;
    this.refund_amount = data.refund_amount || 0;
    this.refund_reason = data.refund_reason || "";
    this.chargeback_reason = data.chargeback_reason || "";
    this.chargeback_date = data.chargeback_date || "";
    this.customer_notes = data.customer_notes || "";
    this.internal_notes = data.internal_notes || "";
    this.payment_state = data.payment_state || "";
    this.error_log = data.error_log || "";
    this.created_at = data.created_at || "";
    this.updated_at = data.updated_at || "";
    this.iscredit = data.iscredit || false;
    this.refund_details = data.refund_details || {};
    this.chargeback_details = data.chargeback_details || {};
    this.adyen_payment_payload = data.adyen_payment_payload || "";
    this.adyen_payment_response = data.adyen_payment_response || "";
    this.dockmaster_payment_payload = data.dockmaster_payment_payload || "";
    this.dockmaster_payment_response = data.dockmaster_payment_response || "";
    this.batch_payload_req = data.batch_payload_req || "";
    this.batch_payload_response = data.batch_payload_response || "";
    this.feeinvoiceid = data.feeinvoiceid || 0;
    this.comment = data.comment || "";
    this.verification_date = data.verification_date || "";
    this.verified_by_user_id = data.verified_by_user_id || 0;
    this.firstname = data.firstname || "";
    this.lastname = data.lastname || "";
    this.total_amount = data.totalamount || 0;
    this.totalamount = data.totalamount || 0;
    this.reference_number = data.referenceNumber || "";
    this.primary_email = data.primaryemail || "";
    this.location_id = data.locationid || "";
    this.paymentProcessor = data.payment_processor || "";
    this.payType = data.pay_type || "";
    this.accountNumber = data.account_number || "";
    this.authCode = data.auth_code || "";
    this.pspReference = data.psp_reference || "";
    this.transStatus = data.trans_status || "";
    this.requestLinkCode = data.request_link_code || "";
    this.location = data.location || "";
    this.id = data.id ? data.id : "";
    this.payment_request_id = data.payment_request_id
      ? data.payment_request_id
      : "";
    this.client_id = data.client_id ? data.client_id : "";
    this.customer_id = data.customer_id ? data.customer_id : "";
    this.batch_id = data.batch_id ? data.batch_id : "";
    this.amount = data.amount ? data.amount : 0;
    this.currency = data.currency ? data.currency : "";
    this.transaction_type = data.transaction_type ? data.transaction_type : "";
    this.status = data.status ? data.status : "";
    this.payment_method = data.payment_method ? data.payment_method : "";
    this.external_transaction_id = data.external_transaction_id
      ? data.external_transaction_id
      : "";
    this.payment_date = data.payment_date ? data.payment_date : "";
    this.partial_payment = data.partial_payment ? data.partial_payment : "";
    this.last_opened = data.last_opened ? data.last_opened : "";
    this.number_of_opens = data.number_of_opens ? data.number_of_opens : 0;
    this.recurring_billing_id = data.recurring_billing_id
      ? data.recurring_billing_id
      : "";
    this.refund_amount = data.refund_amount ? data.refund_amount : 0;
    this.refund_reason = data.refund_reason ? data.refund_reason : "";
    this.chargeback_reason = data.chargeback_reason
      ? data.chargeback_reason
      : "";
    this.chargeback_date = data.chargeback_date ? data.chargeback_date : "";
    this.customer_notes = data.customer_notes ? data.customer_notes : "";
    this.internal_notes = data.internal_notes ? data.internal_notes : "";
    this.payment_state = data.payment_state ? data.payment_state : "";
    this.error_log = data.error_log ? data.error_log : "";
    this.created_at = data.created_at ? data.created_at : "";
    this.updated_at = data.updated_at ? data.updated_at : "";
    this.last_four = data.last_four ? data.last_four : "";
  }
}
