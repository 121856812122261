import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function InvoiceTable({ data, handleChangeAmount, locations }) {
  const hasCheckBoxes = handleChangeAmount ? true : false;
  const [checkBoxesState, setcheckBoxesState] = useState([]);
  
  const defaultCheck = false;

  const getLocationById = (LocationCode) => {
    const item = locations.find((item) => item.id === LocationCode);
    return item ? item.name : "";
  };

  const checkBoxChange = (event) => {
    const newVal = event.target.checked;
    const name = event.target.name;
    if (name === "all") {
      setcheckBoxesState((prev) =>
        prev.map((record) => {
          return { ...record, checked: newVal };
        }),
      );
    }
    setcheckBoxesState((prev) => {
      return prev.map((record) => {
        if (record.id === name) {
          return { ...record, checked: newVal };
        }
        return record;
      });
    });
  };

  useEffect(() => {
    let sum = 0;
    const selectedInvoices = checkBoxesState.filter(
      (record) => record.checked == true,
    );

    sum = selectedInvoices.reduce(
      (total, invoice) => total + invoice.amount,
      0,
    );
    if (handleChangeAmount) {
      handleChangeAmount(sum, selectedInvoices);
    }
  }, [checkBoxesState]);

  useEffect(() => {
    setcheckBoxesState(
      data?.map((record) => {
        return { ...record, checked: defaultCheck };
      }) ?? [],
    );
  }, [data]);

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <table
          cellPadding={10}
          style={{
            border: "1px solid #D0D5DD",
            borderRadius: "5px",
            borderCollapse: "collapse",
          }}
          width={"100%"}
        >
          <tr
            style={{
              color: "#667085",
              fontFamily: "Hanken Grotesk",
              fontWeight: "500",
              fontSize: "14px",
              background: "rgba(252, 252, 253, 1)",
            }}
          >
            <td
              style={{
                display: hasCheckBoxes ? "block" : "none",
              }}
            >
              <Checkbox name="all"  onChange={checkBoxChange} />
            </td>
            <td>Invoice # - Installment #</td>
            <td>Due Date</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              Amount
            </td>
          </tr>

          {checkBoxesState.map((invoice) => (
            <tr
              key={invoice.id}
              style={{
                fontFamily: "Hanken Grotesk",
                fontWeight: "400",
                fontSize: "16px",
                border: "1px solid #D0D5DD",
              }}
            >
              <td style={{ display: hasCheckBoxes ? "block" : "none" }}>
                <Checkbox
                  name={invoice.id}
                  checked={invoice.checked}
                  onChange={checkBoxChange}
                />
              </td>
              <td>
                <div>
                  <div>{invoice.id}</div>
                  <div
                    style={{ fontSize: "12px", color: "rgba(60, 69, 89, 0.5)" }}
                  >
                    Loc: {getLocationById(invoice.locationCode)}
                  </div>
                  <div
                    style={{ fontSize: "12px", color: "rgba(60, 69, 89, 0.5)" }}
                  >
                    {invoice.description}
                  </div>
                </div>
              </td>
              <td>{invoice.dueDate}</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                ${invoice.amount.toFixed(2)}
              </td>
            </tr>
          ))}
        </table>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          fontFamily: "Hanken Grotesk",
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: 2,
              py: 2.5,
              background: "rgba(252, 252, 253, 1)",
              border: "1px solid rgba(208, 213, 221, 1)",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <div>Select all Invoices</div>
            <div style={{ display: hasCheckBoxes ? "block" : "none" }}>
              <Checkbox name="all" onChange={checkBoxChange} />
            </div>
          </Box>
          {checkBoxesState.map((invoice) => (
            <Box
              key={invoice.id + "-mobile"}
              sx={{
                px: 2,
                borderBottom: "1px solid rgba(208, 213, 221, 1)",
                borderLeft: "1px solid rgba(208, 213, 221, 1)",
                borderRight: "1px solid rgba(208, 213, 221, 1)",
              }}
            >
              <Box
                sx={{ py: 1, display: hasCheckBoxes ? "flex" : "none" }}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                  }}
                  variant="body1"
                >
                  {invoice.id}
                </Typography>
                <Checkbox
                  name={invoice.id}
                  checked={invoice.checked}
                  onChange={checkBoxChange}
                />
              </Box>
              <Box sx={{ py: 1 }}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="body2">Invoice #</Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                    }}
                    variant="body1"
                  >
                    {invoice.id}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"baseline"}
                >
                  <Typography variant="body2">Installment #</Typography>
                  <div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgba(60, 69, 89, 0.5)",
                        fontWeight: "600",
                      }}
                    >
                      Loc: Dockmaster
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "rgba(60, 69, 89, 0.5)",
                        fontWeight: "600",
                      }}
                    >
                      {invoice.description}
                    </div>
                  </div>
                </Box>
              </Box>

              <Box
                sx={{ py: 1 }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body2"> Due Date:</Typography>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  {invoice.dueDate}
                </Typography>
              </Box>
              <Box
                sx={{ py: 1, mb: 1 }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body2">Amount:</Typography>
                <Typography sx={{ fontWeight: 600 }} variant="body2">
                  ${invoice.amount.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default InvoiceTable;
