export class Tenant {
  constructor(data = {}) {
    // Standard Fields
    this.ClientID = data.ClientID || null;
    this.ClientName = data.ClientName || '';
    this.Address1 = data.Address1 || '';
    this.Address2 = data.Address2 || '';
    this.City = data.City || '';
    this.State = data.State || '';
    this.Zip = data.Zip || '';
    this.Country = data.Country || '';
    this.TimeZone = data.TimeZone || '';
    this.Type = data.Type || '';
    this.Phone = data.Phone || '';
    this.MobilePhone = data.MobilePhone || '';
    this.Email = data.Email || '';
    this.HomeUrl = data.HomeUrl || '';
    this.Created = data.Created ? new Date(data.Created) : null;
    this.Updated = data.Updated ? new Date(data.Updated) : null;
    this.Disabled = data.Disabled || false;
    this.DisabledMessage = data.DisabledMessage || '';
    this.Inactive = data.Inactive || false;
    this.ContactName = data.ContactName || '';
    this.PaymentModule = data.PaymentModule || false;
    this.GatewayType = data.GatewayType || '';
    this.Clerks = data.Clerks || '';
    this.ClerkNames = data.ClerkNames || '';
    this.ClerkAdmin = data.ClerkAdmin || false;
    this.ClerkExec = data.ClerkExec || false;
    this.ClerkPayProc = data.ClerkPayProc || false;
    this.PayTestMode = data.PayTestMode || false;
    this.WpWelcomeMessage = data.WpWelcomeMessage || '';
    this.DmId = data.DmId || '';
    this.PaymentName = data.PaymentName || '';
    this.PaymentClerk = data.PaymentClerk || '';
    this.PaymentClerkPassword = data.PaymentClerkPassword || '';
    this.PaymentNotificationEmail = data.PaymentNotificationEmail || '';
    this.PaymentVrNotificationEmail = data.PaymentVrNotificationEmail || '';
    this.PaymentFromEmail = data.PaymentFromEmail || '';
    this.PayContactPhone = data.PayContactPhone || '';
    this.PayContactEmail = data.PayContactEmail || '';
    this.PayType = data.PayType || '';
    this.PayPostProc = data.PayPostProc || false;
    this.PayDueDays = data.PayDueDays || '';
    this.PayDisabled = data.PayDisabled || false;
    this.PayDisabledMessage = data.PayDisabledMessage || '';
    this.PayInactiveDate = data.PayInactiveDate ? new Date(data.PayInactiveDate) : null;
    this.DefaultPayLoc = data.DefaultPayLoc || '';
    this.PastDueWarning1 = data.PastDueWarning1 || '';
    this.PastDueWarning2 = data.PastDueWarning2 || '';
    this.PastDueWarning3 = data.PastDueWarning3 || '';
    this.PayLogo = data.PayLogo || '';
    this.PayLogoBin = data.PayLogoBin || null; // Handle binary data carefully, possibly requiring conversion
    this.DepositTypes = data.DepositTypes || '';
    this.DepositTypeEmails = data.DepositTypeEmails || '';
    // Additional fields mapped from Python
    this.CurrencyCode = data.CurrencyCode || "USD";
    this.PaymentRequestIds = data.PaymentRequestIds || null; // Handling JSON
    this.PaymentTransactionIds = data.PaymentTransactionIds || null; // Handling JSON
    this.PaymentIds = data.PaymentIds || null; // Handling JSON
    this.DMConvenienceFeeEnabled = data.DMConvenienceFeeEnabled || false;
    this.ConvenienceFlatFee = data.ConvenienceFlatFee || 0;
    this.ConveniencePercentageFee = data.ConveniencePercentageFee || 0;
    this.ConvenienceFeeMax = data.ConvenienceFeeMax || 0;
    this.CustomConvenienceMsg = data.CustomConvenienceMsg || '';
    this.ACHEnabled = data.ACHEnabled || false;
    this.ReqBillingAddress = data.ReqBillingAddress || false;
    this.EnabledDepositTypes = data.EnabledDepositTypes || []; // Handling array of strings
    this.PaymentsEnabled = data.PaymentsEnabled || false;
    this.PaymentsDisabledMessage = data.PaymentsDisabledMessage || '';
    this.MarkProcessedPayments = data.MarkProcessedPayments || false;
    this.AutoPostPaymentBatch = data.AutoPostPaymentBatch || false;
    this.PutMiscDepositsOnAccount = data.PutMiscDepositsOnAccount || false;
    this.OnAccountLocation = data.OnAccountLocation || '';
    this.ApplyCreditsToInvoice = data.ApplyCreditsToInvoice || false;
    this.NoMiscDeposits = data.NoMiscDeposits || false;
    this.DepositTypesObject = data.DepositTypesObject || null; // Handling JSON
    this.PaymentLocationObject = data.PaymentLocationObject || null; // Handling JSON
    this.PaymentFormName = data.PaymentFormName || '';
    this.PaymentFormContactPhone = data.PaymentFormContactPhone || '';
    this.PaymentNotificationEmails = data.PaymentNotificationEmails || []; // Handling array of strings
    this.VoidRefundFormContactPhone = data.VoidRefundFormContactPhone || '';
    this.PaymentContactEmail = data.PaymentContactEmail || '';
    this.BccDepositRequestEmail = data.BccDepositRequestEmail || '';
    this.AcceptedCreditCards = data.AcceptedCreditCards || []; // Handling array of strings
    this.AcceptAchPayments = data.AcceptAchPayments || false;
    this.RequireBillingAddress = data.RequireBillingAddress || false;
    this.PayOldestInvoiceFirst = data.PayOldestInvoiceFirst || false;
    this.MaximumCcPaymentAmount = data.MaximumCcPaymentAmount || 0;
    this.DMConvenienceFeeSurchargeEnabled = data.DMConvenienceFeeSurchargeEnabled || false;
    this.ConvenienceFeeSurchargeEnabled = data.ConvenienceFeeSurchargeEnabled || false;
    this.SurchargePercent = data.SurchargePercent || 0;
    this.SurchargeMaximumFee = data.SurchargeMaximumFee || 0;
    this.AchConvenienceFeeSurchargeEnabled = data.AchConvenienceFeeSurchargeEnabled || false;
    this.AchConvenienceFlatFee = data.AchConvenienceFlatFee || 0;
    this.AchConvenienceMessage = data.AchConvenienceMessage || '';
    this.AchSurchargePercent = data.AchSurchargePercent || 0;
    this.AchSurchargeMaximumFee = data.AchSurchargeMaximumFee || 0;
    this.PaymentDueDays = data.PaymentDueDays || null;
    this.PastDueWarningOne = data.PastDueWarningOne || null; // Handling JSON
    this.PastDueWarningTwo = data.PastDueWarningTwo || null; // Handling JSON
    this.PastDueWarningThree = data.PastDueWarningThree || null; // Handling JSON
    this.PaymentFormImage = data.PaymentFormImage || '';
    this.SendPdfConfirmation = data.SendPdfConfirmation || false;
    this.SendPdfNotification = data.SendPdfNotification || false;
    this.UsePaidRequestTexting = data.UsePaidRequestTexting || false;
    this.PayFirstBillingPeriod = data.PayFirstBillingPeriod ? new Date(data.PayFirstBillingPeriod) : null;
    this.PaySetupCharge = data.PaySetupCharge || 500;
    this.PayMonthlyCharge = data.PayMonthlyCharge || 99;
    this.PayPremiumTextMonthlyChange = data.PayPremiumTextMonthlyChange || 10;
    this.PayTransactionCommissionCharge = data.PayTransactionCommissionCharge || 0.1;
    this.PayVolumeCommissionPercent = data.PayVolumeCommissionPercent || 0.005;
  }
}
