export class SecurityGroup {
    constructor(data) {
      this.id = data.id || '';
      this.name = data.name || data.id || '';
      this.type = data.type || '';
      this.num_users = data.num_users || 0;
      this.description = data.description || '';
      this.menuitems = data.menuitems || '';
      this.dmonly = data.dmonly || '';
    }
  }
  