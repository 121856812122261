import { BASE_URL } from "../config";
import { PaymentRequest as PaymentRequestModel } from "../models/PaymentRequest";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getPaymentRequestAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`payment_requests/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getPaymentRequests = async (filters = {}, clientID) => {
  let processedFilters = { ...filters };
  if (filters.totalamount) {
    processedFilters.totalamount = parseFloat(filters.totalamount);
  }
  if (filters.depositamount) {
    processedFilters.depositamount = parseFloat(filters.depositamount);
  }
  try {
    const filtersParam = encodeURIComponent(JSON.stringify(processedFilters));
    const response = await axiosInstance.get(
      `${BASE_URL}/payment_requests/?filters=${filtersParam}&client_id=${clientID}`,
    );
    const data = response.data.data.map(
      (item) => new PaymentRequestModel(item),
    );
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const createPaymentRequestAPI = async (newPaymentRequest) => {
  try {
    const response = await axiosInstance.post(
      `payment_requests/`,
      newPaymentRequest,
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const sendPaymentRequestAPI = async (id, requestData) => {
  try {
    const response = await axiosInstance.post(
      `payment_requests/sendpayrequest/${id}`,
      {},
      {
        params: requestData,
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const updatePaymentRequest = async (updatedPaymentRequest) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/payment_requests/${updatedPaymentRequest.id}`,
      updatedPaymentRequest,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = new PaymentRequestModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Payment request updated successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const deletePaymentRequest = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/payment_requests/${id}`,
    );
    return new StandardResponse(
      {},
      response.data.status_code,
      "Payment request deleted successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

export {
  getPaymentRequestAPI,
  getPaymentRequests,
  createPaymentRequestAPI,
  updatePaymentRequest,
  deletePaymentRequest,
  sendPaymentRequestAPI,
};
