export class Prospect {
  constructor(data) {
    this.prospectId = data.prospectId || data.ProspectId || "";
    this.customerId = data.customerId || data.CustomerId || "";
    this.salesman = data.salesman || data.Salesman || data.salesMan || "";
    this.fullName = data?.name ?? data.lastName + ", " + data.firstName;
    this.dateEntered = data.dateEntered || data.DateEntered || "";
    this.status = data.status || data.Status || "";
    this.emailAddress =
      data.emailAddress || data.EmailAddress || data.email || "";
    this.address = data.address1 ?? "";
  }
}
