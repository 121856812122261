import { LoginResponseModel } from '../models/Login';
import { StandardResponse } from '../assets/StandardResponse';
import axiosInstance from '../helpers/axiosInstance';

const GRANT_TYPE = '';
const SCOPE = '';
const CLIENT_ID = '';
const CLIENT_SECRET = '';


const LoginUser = async (username, password) => {
    const requestBody = new URLSearchParams({
      grant_type: GRANT_TYPE,
      username: username,
      password: password,
      scope: SCOPE,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    });

    const response = await axiosInstance.post(`auth/access-token`, requestBody.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    const jsonResponse = response.data;

    if (response.status === 200) {
      const loginResponse = new LoginResponseModel(jsonResponse);
      return new StandardResponse(loginResponse, 200, 'Login successful.');
    }  
    throw new Error('Login failed. Please check your credentials.');
     
};


export { LoginUser };
