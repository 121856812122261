export class CustomerInvoices {
  constructor(data) {
    this.customer_email = data.customer_email || data.Customer_Email || "";
    this.customer_phone = data.customer_phone || data.Customer_Phone || "";
    this.merchant_id = data.merchant_id || data.Merchant_Id || "";
    this.submerchant_id = data.submerchant_id || data.Submerchant_Id || "";
    this.open_invoices = new Customer(data.open_invoices || data.Open_Invoices);
    this.tenant_information = new TenantInformation(data.tenant_information || data.Tenant_Information);
  }
}


class TenantInformation {
  constructor({
    ClientID,
    ClientName,
    Address1,
    Address2,
    City,
    State,
    Zip,
    Country,
    TimeZone,
    Type,
    Phone,
    MobilePhone,
    Email,
    HomeUrl,
    Created,
    Updated,
    Disabled,
    DisabledMessage,
    Inactive,
    ContactName,
    PaymentModule,
    GatewayType,
    PayType,
    PayPostProc,
    PayDueDays,
    PayDisabled,
    PayDisabledMessage,
    PayInactiveDate,
    DefaultPayLoc,
    PastDueWarning1,
    PastDueWarning2,
    PastDueWarning3,
    PayLogo,
    DepositTypes,
    DepositTypeEmails,
    DMConvenienceFeeEnabled,
    ConvenienceFlatFee,
    ConveniencePercentageFee,
    ConvenienceFeeMax,
    CustomConvenienceMsg,
    ACHEnabled,
    CurrencyCode,
    ReqBillingAddress,
    EnabledDepositTypes,
    PaymentsEnabled,
    PaymentsDisabledMessage,
    MarkProcessedPayments,
    AutoPostPaymentBatch,
    PutMiscDepositsOnAccount,
    OnAccountLocation,
    ApplyCreditsToInvoice,
    NoMiscDeposits,
    DepositTypesObject,
    PaymentLocationObject,
    PaymentFormName,
    PaymentFormContactPhone,
    PaymentNotificationEmails,
    VoidRefundFormContactPhone,
    PaymentContactEmail,
    BccDepositRequestEmail,
    AcceptedCreditCards,
    AcceptAchPayments,
    RequireBillingAddress,
    PayOldestInvoiceFirst,
    MaximumCcPaymentAmount,
    DMConvenienceFeeSurchargeEnabled,
    ConvenienceFeeSurchargeEnabled,
    ConvenienceMessage,
    SurchargePercent,
    SurchargeMaximumFee,
    AchConvenienceFeeSurchargeEnabled,
    AchConvenienceFlatFee,
    AchConvenienceMessage,
    AchSurchargePercent,
    AchSurchargeMaximumFee,
    PaymentDueDays,
    PastDueWarningOne,
    PastDueWarningTwo,
    PastDueWarningThree,
    PaymentFormImage,
    SendPdfConfirmation,
    SendPdfNotification,
    UsePaidRequestTexting,
    PayFirstBillingPeriod,
    PaySetupCharge,
    PayMonthlyCharge,
    PayPremiumTextMonthlyChange,
    PayTransactionCommissionCharge,
    PayVolumeCommissionPercent,
  }) {
    this.ClientID = ClientID;
    this.ClientName = ClientName;
    this.Address1 = Address1;
    this.Address2 = Address2;
    this.City = City;
    this.State = State;
    this.Zip = Zip;
    this.Country = Country;
    this.TimeZone = TimeZone;
    this.Type = Type;
    this.Phone = Phone;
    this.MobilePhone = MobilePhone;
    this.Email = Email;
    this.HomeUrl = HomeUrl;
    this.Created = Created;
    this.Updated = Updated;
    this.Disabled = Disabled;
    this.DisabledMessage = DisabledMessage;
    this.Inactive = Inactive;
    this.ContactName = ContactName;
    this.PaymentModule = PaymentModule;
    this.GatewayType = GatewayType;
    this.PayType = PayType;
    this.PayPostProc = PayPostProc;
    this.PayDueDays = PayDueDays;
    this.PayDisabled = PayDisabled;
    this.PayDisabledMessage = PayDisabledMessage;
    this.PayInactiveDate = PayInactiveDate;
    this.DefaultPayLoc = DefaultPayLoc;
    this.PastDueWarning1 = PastDueWarning1;
    this.PastDueWarning2 = PastDueWarning2;
    this.PastDueWarning3 = PastDueWarning3;
    this.PayLogo = PayLogo;
    this.DepositTypes = DepositTypes;
    this.DepositTypeEmails = DepositTypeEmails;
    this.DMConvenienceFeeEnabled = DMConvenienceFeeEnabled;
    this.ConvenienceFlatFee = ConvenienceFlatFee;
    this.ConveniencePercentageFee = ConveniencePercentageFee;
    this.ConvenienceFeeMax = ConvenienceFeeMax;
    this.CustomConvenienceMsg = CustomConvenienceMsg;
    this.ACHEnabled = ACHEnabled;
    this.CurrencyCode = CurrencyCode;
    this.ReqBillingAddress = ReqBillingAddress;
    this.EnabledDepositTypes = EnabledDepositTypes;
    this.PaymentsEnabled = PaymentsEnabled;
    this.PaymentsDisabledMessage = PaymentsDisabledMessage;
    this.MarkProcessedPayments = MarkProcessedPayments;
    this.AutoPostPaymentBatch = AutoPostPaymentBatch;
    this.PutMiscDepositsOnAccount = PutMiscDepositsOnAccount;
    this.OnAccountLocation = OnAccountLocation;
    this.ApplyCreditsToInvoice = ApplyCreditsToInvoice;
    this.NoMiscDeposits = NoMiscDeposits;
    this.DepositTypesObject = DepositTypesObject;
    this.PaymentLocationObject = PaymentLocationObject;
    this.PaymentFormName = PaymentFormName;
    this.PaymentFormContactPhone = PaymentFormContactPhone;
    this.PaymentNotificationEmails = PaymentNotificationEmails;
    this.VoidRefundFormContactPhone = VoidRefundFormContactPhone;
    this.PaymentContactEmail = PaymentContactEmail;
    this.BccDepositRequestEmail = BccDepositRequestEmail;
    this.AcceptedCreditCards = AcceptedCreditCards;
    this.AcceptAchPayments = AcceptAchPayments;
    this.RequireBillingAddress = RequireBillingAddress;
    this.PayOldestInvoiceFirst = PayOldestInvoiceFirst;
    this.MaximumCcPaymentAmount = MaximumCcPaymentAmount;
    this.DMConvenienceFeeSurchargeEnabled = DMConvenienceFeeSurchargeEnabled;
    this.ConvenienceFeeSurchargeEnabled = ConvenienceFeeSurchargeEnabled;
    this.ConvenienceMessage = ConvenienceMessage;
    this.SurchargePercent = SurchargePercent;
    this.SurchargeMaximumFee = SurchargeMaximumFee;
    this.AchConvenienceFeeSurchargeEnabled = AchConvenienceFeeSurchargeEnabled;
    this.AchConvenienceFlatFee = AchConvenienceFlatFee;
    this.AchConvenienceMessage = AchConvenienceMessage;
    this.AchSurchargePercent = AchSurchargePercent;
    this.AchSurchargeMaximumFee = AchSurchargeMaximumFee;
    this.PaymentDueDays = PaymentDueDays;
    this.PastDueWarningOne = PastDueWarningOne;
    this.PastDueWarningTwo = PastDueWarningTwo;
    this.PastDueWarningThree = PastDueWarningThree;
    this.PaymentFormImage = PaymentFormImage;
    this.SendPdfConfirmation = SendPdfConfirmation;
    this.SendPdfNotification = SendPdfNotification;
    this.UsePaidRequestTexting = UsePaidRequestTexting;
    this.PayFirstBillingPeriod = PayFirstBillingPeriod;
    this.PaySetupCharge = PaySetupCharge;
    this.PayMonthlyCharge = PayMonthlyCharge;
    this.PayPremiumTextMonthlyChange = PayPremiumTextMonthlyChange;
    this.PayTransactionCommissionCharge = PayTransactionCommissionCharge;
    this.PayVolumeCommissionPercent = PayVolumeCommissionPercent;
  }
}

class Invoice {
  constructor(invoiceData) {
    this.id = invoiceData.id;
    this.customerId = invoiceData.customerId;
    this.amount = invoiceData.amount;
    this.invoiceAmount = invoiceData.invoiceAmount;
    this.invoiceBalance = invoiceData.invoiceBalance;
    this.dueDate = invoiceData.dueDate;
    this.description = invoiceData.description;
    this.locationCode = invoiceData.locationCode;
    this.unAppliedPayment = invoiceData.unAppliedPayment;
    this.installments = invoiceData.installments;
  }
}

class Payment {
  constructor(paymentData) {
    this.paymentDate = paymentData.paymentDate;
    this.paymentAmount = paymentData.paymentAmount;
    this.paymentMethod = paymentData.paymentMethod;
  }
}

class Customer {
  constructor(customerData) {
    this.customerId = customerData.customerId || customerData.CustomerId || "";
    this.firstName = customerData.firstName || customerData.FirstName || "";
    this.lastName = customerData.lastName || customerData.LastName || "";
    this.email = customerData.email || customerData.Email || "";
    this.homePhone = customerData.homePhone || customerData.HomePhone || "";
    this.cellPhone = customerData.cellPhone || customerData.CellPhone || "";
    this.companyName = customerData.companyName || customerData.CompanyName || "";
    this.currentBalance = customerData.currentBalance || customerData.CurrentBalance || 0;
    this.openARInvoices = Array.isArray(customerData.openARInvoices) ? customerData.openARInvoices.map(invoice => new Invoice(invoice)) : [];
    this.pendingPayments = customerData.pendingPayments || customerData.PendingPayments ||[]; 
    this.lastPayment = new Payment(customerData.lastPayment || {});
  }
}

