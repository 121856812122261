import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default function NumberInputBasic(props) {
  return <NumberInput {...props} />;
}

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  border-radius: 4px;
    font-weight: 400;
font-size:1rem;
box-sizing: border-box;
color:rgba(0, 0, 0, 0.87);
border: 1px solid rgba(0, 0, 0, 0.23);
background: #F0F2F5;
  &.${numberInputClasses.focused} {
    box-shadow: 0 0 0 1px  ${blue[500]};

    }

  &:hover {
    border-color: ${grey[900]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  font-family: inherit;
  letter-spacing:inherit;
  border: 0;
  background: none;
  padding: 8.5px 14px;
  height:1.4375em;
  margin: 0;
  width: 100%;

  
  font-size: 0.875rem;
  font-weight: 400;
   grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
  border-radius: inherit;
  outline: 0;
`,
);

const StyledButton = styled("button")(({ theme }) => `display: none;`);
