import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const GeneralRadioGroup = ({ label, defaultValue, name, options, onChange, required }) => {
  return (
    <FormControl>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={`${name}-label`}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        required={required}
      >
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default GeneralRadioGroup;
