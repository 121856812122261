import axiosInstance from "../helpers/axiosInstance";

const sendSMSAPI = async (text, numbers) => {
  try {
    const response = await axiosInstance.post(
      `sms_email/send_sms`,
      [...numbers],
      {
        params: {
          text: text,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const sendEmailAPI = async (emails, subject, body, includeAttachment = false) => {
  try {
    let data = {
      to: [...emails],
      subject: subject,
      body: body,
      plainbody: body,
    }
    
    if (includeAttachment) {
      data["include_attachment"] = true;
      data["pdf"] = body;
    }
    
    const response = await axiosInstance.post(`sms_email/send_email`, data);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

export { sendSMSAPI, sendEmailAPI };
