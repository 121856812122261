import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";

import FormControlLabel from "@mui/material/FormControlLabel";

import { ReactComponent as DebitCard } from "../assets/Svg/debitCard.svg";
import { ReactComponent as Check } from "../assets/Svg/check.svg";
import { ReactComponent as CreditCard } from "../assets/Svg/creditCard.svg";

function PaymentMethodPicker({
  handlePaymentChange,
  AcceptAchPayments,
  cards,
  selectedMethod,
}) {
  const [selectedValue, setSelectedValue] = React.useState(selectedMethod);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    handlePaymentChange(event.target.value);
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(208, 213, 221, 1)",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      {cards.length > 0 && (
        <>
          <Box
            sx={{
              borderBottom: "1px solid rgba(208, 213, 221, 1)",
            }}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel
              sx={{ marginY: 0, px: 2.5, py: 3, flexGrow: 1 }}
              fullwidth
              checked={selectedValue === "CC"}
              onChange={handleChange}
              value="CC"
              control={<Radio />}
              label="Credit card"
            />
            <Box
              sx={{
                px: 2.5,
                py: 3,
                overflow: "hidden",
              }}
            >
              <CreditCard />
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid rgba(208, 213, 221, 1)",
            }}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel
              sx={{ marginY: 0, px: 2.5, py: 3, flexGrow: 1 }}
              checked={selectedValue === "DC"}
              onChange={handleChange}
              value="DC"
              control={<Radio />}
              label="Debit card"
            />
            <Box
              sx={{
                px: 2.5,
                py: 3,
                overflow: "hidden",
              }}
            >
              <DebitCard />
            </Box>
          </Box>
        </>
      )}
      {AcceptAchPayments && (
        <Box
          sx={{}}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <FormControlLabel
            sx={{ marginY: 0, px: 2.5, py: 3, flexGrow: 1 }}
            checked={selectedValue === "ACH"}
            onChange={handleChange}
            value="ACH"
            control={<Radio />}
            label="eCheck(ACH)"
          />
          <Box
            sx={{
              px: 2.5,
              py: 3,
              overflow: "hidden",
            }}
          >
            <Check />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default PaymentMethodPicker;
