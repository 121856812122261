import axios from "axios";
import { createRefreshToken } from "../middlewares/refreshToken";

import {
  requestSuccessInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptor,
} from "../middlewares/interceptors";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
  timeout: 100000,
});

createRefreshToken(axiosInstance);

axiosInstance.interceptors.request.use((request) =>
  requestSuccessInterceptor(request),
);
axiosInstance.interceptors.response.use(
  (response) => responseSuccessInterceptor(response),
  (error) => responseErrorInterceptor(error),
);

export default axiosInstance;
