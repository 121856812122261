import { Credential as CredentialModel } from "../models/Credential";
import { StandardResponse } from "../assets/StandardResponse";

import axiosInstance from "../helpers/axiosInstance";

const getClientCredentialsAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`credentials/client/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getClientKeyByTenantId = async (tenantId) => {
  try {
    const response = await axiosInstance.get(`credentials/client/key/${tenantId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const createCredential = async (newCredential) => {
  try {
    const response = await axiosInstance.post(`credentials/`, newCredential, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new CredentialModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        "Credential created successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to create the credential";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "Failed to create credential due to a network or parsing error.",
    );
  }
};

const updateCredential = async (id, updatedCredential) => {
  try {
    const response = await axiosInstance.put(
      ` credentials/${id}/`,
      updatedCredential,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new CredentialModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message || "Credential updated successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to update the credential";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const deleteCredential = async (id) => {
  try {
    const response = await axiosInstance.delete(`credentials/${id}/`);

    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      return new StandardResponse(
        null,
        jsonResponse.status_code,
        jsonResponse.message || "Credential deleted successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to delete the credential";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const updateCredentialByClientId = async (clientId, updatedCredential) => {
  try {
    const response = await axiosInstance.put(
      `credentials/client/${clientId}`,
      updatedCredential,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = new CredentialModel(jsonResponse.data);
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message || "Credential updated successfully",
      );
    } else {
      const errorMessage =
        jsonResponse.message || "Failed to update the credential";
      return new StandardResponse(
        {},
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      {},
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const getCredentialsByClientId = async (clientId) => {
  try {
    const response = await axiosInstance.get(`credentials/client/${clientId}`);

    const data = response.data.data.map((item) => new CredentialModel(item));
    return new StandardResponse(data, 200, "success");
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

export {
  getClientCredentialsAPI,
  getClientKeyByTenantId,
  createCredential,
  updateCredential,
  deleteCredential,
  updateCredentialByClientId,
  getCredentialsByClientId,
};
