import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, rowData, itemID }) => {
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{"Are you sure you want to delete this item?"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to delete the item with ID: ${rowData ? rowData[itemID] : ''}? This action cannot be undone.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                    onConfirm(rowData);
                    onClose();
                }} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteConfirmationDialog;
