import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";
import TerminalFormDialogElement from "./TerminalFormDialogElement";
import DisplayGeneralData from "../drawer/DisplayGeneralData";
import CustomTabs from "../Tabs";
import {
  getLocationsAPI,
  getCustomerContracts,
  getCustomerQuotes,
  getCustomerSpecialOrders,
  getCustomerOpenWorkOrders,
} from "../../api/DockMasterAPI";
import { getTenantAPI } from "../../api/TenantAPI";
import { getAuthCookies } from "../../context/CookieManager";

const TerminalPaymentDialog = ({
  customerInfo,
  serviceType,
  createElementDialogData,
  rowData,
  open,
  onClose,
  onSubmit,
  disabledSave,
  disabledPopOver,
  sendRequest,
  PaymentRequestUUID,
  DefaultPayLoc,
}) => {
  const [tabs] = useState(createElementDialogData.tabs);
  const [loading, setLoading] = useState(false);
  const user_id = getAuthCookies("tenantId");
  const [prefetchedOptions, setPreFetchedOptions] = useState({});
  const [tenantInfo, setTenantInfo] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      if (!open) return;
      setLoading(true);

      try {
        const [
          tenantData,
          customerContractsData,
          customerQuotesData,
          customerBoats,
          customerInvoices,
          customerSpecialOrders,
          customerSpecificInvoice,
          allInvoices,
          customerWorkOrdersData,
          customerWaitListData,
        ] = await Promise.all([
          getTenantAPI(user_id),
          getCustomerContracts(user_id, customerInfo.id),
          getCustomerQuotes(user_id, customerInfo.id),
          customerInfo.boats,
          customerInfo.invoices,
          getCustomerSpecialOrders(user_id, customerInfo.id),
          customerInfo.invoices,
          customerInfo.invoices,
          getCustomerOpenWorkOrders(user_id, customerInfo.id),
          customerInfo.waitListEntries,
        ]);
        setTenantInfo(tenantData);

        // Ensure each data fetch is correctly an array

        const safeLocationsData =
          tenantData?.PaymentLocationObject?.filter((loc) => loc.enabled) ?? [];
        const safeCustomerWorkOrdersData = Array.isArray(
          customerWorkOrdersData.data,
        )
          ? customerWorkOrdersData.data
          : [];
        const safeDepositTypes = Array.isArray(tenantData?.DepositTypesObject)
          ? tenantData.DepositTypesObject.filter(
              (deposit) => deposit.enabled === true,
            )
          : [];
        const safeCustomerContractsData = Array.isArray(
          customerContractsData.data,
        )
          ? customerContractsData.data
          : [];
        const safeCustomerQuotesData = Array.isArray(customerQuotesData.data)
          ? customerQuotesData.data
          : [];
        const safeCustomerBoats = Array.isArray(customerBoats)
          ? customerBoats
          : [];
        const safeCustomerInvoices = Array.isArray(customerInvoices)
          ? customerInvoices
          : [];
        const safeCustomerSpecialOrders = Array.isArray(
          customerSpecialOrders.data,
        )
          ? customerSpecialOrders.data
          : [];
        let safeCustomerSpecificInvoice = Array.isArray(customerSpecificInvoice)
          ? customerSpecificInvoice
          : [];
        const safeAllInvoices = Array.isArray(allInvoices) ? allInvoices : [];
        const safeCustomerWaitListData = Array.isArray(customerWaitListData)
          ? customerWaitListData
          : [];

        const locationCodes = safeLocationsData.map((location) => location.Id);
        // Filter invoices based on the extracted location codes
        const filteredInvoices = safeAllInvoices.filter((invoice) =>
          locationCodes.includes(invoice.locationCode),
        );

        safeCustomerSpecificInvoice = [...filteredInvoices];
        setPreFetchedOptions({
          locations: safeLocationsData.map((item) => ({
            value: item.Id,
            label: `${item.Name}, ID: ${item.Id}`,
          })),
          customerWorkOrders: safeCustomerWorkOrdersData.map((item) => ({
            value: item.id,
            label: `${item.id}, Customer: ${item.customer}, Open Date: ${item.openDate}, Boat: ${item.boat}`,
            locationCode: item.locationCode,
          })),
          depositTypes: safeDepositTypes.map((item) => ({
            value: item.id,
            label: item.description,
            description: item.description,
          })),
          customerContracts: safeCustomerContractsData.map((item) => ({
            value: item.contractId,
            label: `${item.contractId}, Salesman: ${item.salesMan}, Sales Date: ${item.salesDate}`,
            locationCode: item.locationCode,
          })),
          customerQuotes: safeCustomerQuotesData.map((item) => ({
            value: item.contractId,
            label: `${item.contractId}, Salesman: ${item.salesMan}, Sales Date: ${item.salesDate}`,
            locationCode: item.locationCode,
          })),
          customerBoats: safeCustomerBoats.map((item) => ({
            value: item.id,
            label: `${item.id}, OwnerId: ${item.ownerId}, Year: ${item.year}`,
            // No locationCode in boats
          })),
          customerInvoices: safeCustomerInvoices.map((item) => ({
            ...item,
            value: item.id,
            label: `Id: ${item.id}, Description: ${item.description}, Amount: ${item.amount}`,
            locationCode: item.locationCode,
            amount: item.amount,
          })),
          customerSpecialOrders: safeCustomerSpecialOrders.map((item) => ({
            value: item.id,
            label: `Id: ${item.id}, PO: ${
              item.purchaseOrder || "none"
            }, Date: ${item.dateEntered}`,
            locationCode: item.locationCode,
          })),
          specificInvoice: safeCustomerSpecificInvoice.map((item) => ({
            value: item.id,
            label: `Id: ${item.id}, Description: ${
              item.description || "none"
            }, Amount: ${item.amount || 0}, Due Date: ${item.dueDate}`,
            locationCode: item.locationCode,
          })),
          allInvoices: filteredInvoices,
          customerWaitList: safeCustomerWaitListData.map((item) => ({
            value: item.id,
            label: `Id: ${item.id}, Description: ${
              item.description || "none"
            }, Boat Id: ${item.boatId || 0}, Entry Date: ${item.entryDate}`,
            locationCode: item.locationCode,
          })),
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [open, user_id, customerInfo.id]);

  const renderTabContent = (content) => {
    return (
      <TerminalFormDialogElement
        tenantData={tenantInfo}
        prefetchedOptions={prefetchedOptions}
        serviceType={serviceType}
        elements={content.elements}
        onClose={onClose}
        onSubmit={onSubmit}
        buttonText={createElementDialogData.buttonText}
        customerInfo={customerInfo}
        disabledSave={disabledSave}
        disabledPopOver={disabledPopOver}
        sendRequest={sendRequest}
        PaymentRequestUUID={PaymentRequestUUID}
        DefaultPayLoc={DefaultPayLoc}
      />
    );
  };

  let header;
  if (serviceType === "securityGroup") {
    header = "Create New Security Group";
  } else if (serviceType === "userMaintenance") {
    header = "Create New User";
  } else if (serviceType === "clientMaintenance") {
    header = "Create New Client";
  } else if (serviceType === "terminal") {
    header = "Terminal";
  } else {
    header = "Create New Item";
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            position: "relative",
            overflow: "hidden",
          },
        }}
      >
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 2000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DialogTitle>{header}</DialogTitle>
        <Box sx={{ p: 3 }}>
          {console.log("rowData", rowData)}
          <DisplayGeneralData
            rowData={rowData}
            fields={createElementDialogData.fields}
          />
        </Box>
        <Divider />

        {!loading && (
          <DialogContent>
            <CustomTabs
              tabs={tabs.map((tab) => ({
                label: tab.label,
                component: renderTabContent(tab.content),
              }))}
            />
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default TerminalPaymentDialog;
