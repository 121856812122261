export class Customer {
  constructor(data) {
    this.id = data.customerID || data.CustomerID || "";
    this.name = data.name || data.Name || "";
    this.email = data.email || data.Email || "";
    this.address1 = data.address || data.Address || "";
    this.companyName = data.companyName || data.CompanyName || "";
    this.prospectId = data.prospectId || data.ProspectId || "";
    this.phone = data.Phone || data.phone || "";
  }
}
