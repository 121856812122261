export class LoginCredentials {
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }
}

export class LoginResponseModel {
  constructor(responseData) {
    this.tokenType = responseData.token_type;
    this.accessToken = responseData.access_token;
    this.expiresAt = responseData.expires_at;
    this.issuedAt = responseData.issued_at;
    this.refreshToken = responseData.refresh_token;
    this.refreshTokenExpiresAt = responseData.refresh_token_expires_at;
    this.refreshTokenIssuedAt = responseData.refresh_token_issued_at;
    this.securityGroup = responseData.securitygroup;
    this.tenant_id = responseData.tenant_id;
    this.permissions = responseData.permissions;
    this.user_id = responseData.user_id;
  }
}
