import React from 'react'
import Box from '@mui/material/Box';

function Error() {
  return (
    <Box sx={{
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '2rem', pt: 5
    }}>Something went Wrong<br/>Please contact support</Box>
  )
}

export default Error