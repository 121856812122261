import Cookies from "js-cookie";

export const setAuthCookies = (loginResponse, username) => {
  const accessTokenExpiresInDays =
    (loginResponse.expiresAt - loginResponse.issuedAt) / 86400;
  const refreshTokenExpiresInDays =
    (loginResponse.refreshTokenExpiresAt - loginResponse.refreshTokenIssuedAt) /
    86400;

  Cookies.set("username", username, { expires: accessTokenExpiresInDays });
  Cookies.set("tokenType", loginResponse.tokenType, {
    expires: accessTokenExpiresInDays,
  });
  Cookies.set("accessToken", loginResponse.accessToken, {
    expires: accessTokenExpiresInDays,
  });
  Cookies.set("refreshToken", loginResponse.refreshToken, {
    expires: refreshTokenExpiresInDays,
  });
  Cookies.set("securityGroup", loginResponse.securityGroup, {
    expires: accessTokenExpiresInDays,
  });
  Cookies.set("permissions", loginResponse.permissions, {
    expires: accessTokenExpiresInDays,
  });
  Cookies.set("tenantId", loginResponse.tenant_id, {
    expires: accessTokenExpiresInDays,
  });
  Cookies.set("userId", loginResponse.user_id, {
    expires: accessTokenExpiresInDays,
  });
};

export const setCookies = (name, val) => {
  Cookies.set(name, val);
};
export const getAuthCookies = (string) => {
  if (string) {
    return Cookies.get(string);
  } else
    return {
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
      username: Cookies.get("username"),
      voidRefundPermission: Cookies.get("voidRefundPermission"),
    };
};

export const clearAuthCookies = () => {
  Cookies.remove("username");
  Cookies.remove("tokenType");
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("accessToken");
  Cookies.remove("securityGroup");
  Cookies.remove("tenantId");
  Cookies.remove("permissions");
  Cookies.remove("userId");
  Cookies.remove("voidRefundPermission");
};
