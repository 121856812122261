export class SpecialOrder {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.customerId = data.customerId || data.CustomerId || "";
    this.boatId = data.boatId || data.BoatId || "";
    this.clerkId = data.clerkId || data.ClerkId || "";
    this.vendorId = data.vendorId || data.VendorId || "";
    this.orderNumber = data.orderNumber || data.OrderNumber || "";
    this.comments = data.comments || data.Comments || "";
    this.dateEntered = data.dateEntered || data.DateEntered || "";
    this.depositAmount = data.depositAmount || data.DepositAmount || 0;
    this.module = data.module || data.Module || "";
    this.sourceDocument = data.sourceDocument || data.SourceDocument || "";
    this.purchaseOrder = data.purchaseOrder || data.PurchaseOrder || "";
    this.depositFixed = data.depositFixed || data.DepositFixed || false;
    this.depositApplied = data.depositApplied || data.DepositApplied || false;
    this.appliedTicket = data.appliedTicket || data.AppliedTicket || false;
    this.locationCode = data.locationCode || data.LocationCode || "";
    this.locationValue = data.locationValue || data.LocationValue || "";
    this.depositReturned = data.depositReturned || data.DepositReturned || false;
    this.posFreight = data.posFreight || data.PosFreight || 0;
    this.posAppliedFreight = data.posAppliedFreight || data.PosAppliedFreight || 0;
    this.specialOrderParts = Array.isArray(data.specialOrderParts)
      ? data.specialOrderParts.map(part => new SpecialOrderPart(part))
      : (Array.isArray(data.SpecialOrderParts) ? data.SpecialOrderParts.map(part => new SpecialOrderPart(part)) : []);
  }
}

export class SpecialOrderPart {
  constructor(data) {
    this.partId = data.partId || data.PartId || "";
    this.qtyOrdered = data.qtyOrdered || data.QtyOrdered || 0;
    this.price = data.price || data.Price || 0;
    this.extension = data.extension || data.Extension || 0;
    this.qtyReceived = data.qtyReceived || data.QtyReceived || 0;
    this.qtyClosed = data.qtyClosed || data.QtyClosed || 0;
    this.qtyDelivered = data.qtyDelivered || data.QtyDelivered || 0;
    this.taxSchema = data.taxSchema || data.TaxSchema || "";
    this.opCodeTechId = data.opCodeTechId || data.OpCodeTechId || "";
    this.priceOverride = data.priceOverride || data.PriceOverride || false;
  }
}
