import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Tabs, Tab } from "@mui/material";

function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
}

const CustomTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="custom tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`custom-tabpanel-${index}`}
          aria-labelledby={`custom-tab-${index}`}
          key={index}
        >
          {value === index && (
            <Box sx={{ pt: 3 }}>
              <Typography component={"span"}>{tab.component}</Typography>
            </Box>
          )}
        </div>
      ))}
    </>
  );
};
CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default CustomTabs;
