import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { checkCodeValidity } from "../api/Authentication";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setCookies } from "../context/CookieManager";
import TextField from "@mui/material/TextField";

function Otp() {
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const paymentRequestId = queryParams.get("id");
    const tenantId = queryParams.get("tenant-id");
    const customerId = queryParams.get("customer-id");
    if (!paymentRequestId && !tenantId && !customerId) {
      navigate("/page-not-found");
    }
  }, []);

  const onChangeOtp = (val) => {
    setOtp(val);
  };
  const submit = () => {
    checkOtpValidity(otp);
  };
  const checkOtpValidity = async (otp) => {
    try {
      setDisabled(true);
      const paymentRequestId = queryParams.get("id");
      const tenantId = queryParams.get("tenant-id");
      const customerId = queryParams.get("customer-id");
      if (paymentRequestId) {
        const response = await checkCodeValidity({
          payment_request_id: paymentRequestId,
          ZIP: otp,
        });
        setCookies("accessToken", response.access_token);
        navigate(`/dmpay?id=${paymentRequestId}`);
      } else {
        const response = await checkCodeValidity({
          tenant_id: tenantId,
          customer_id: customerId,
          ZIP: otp,
        });
        setCookies("accessToken", response.access_token);
        navigate(`/pay/${customerId}/${tenantId}`);
      }
    } catch (error) {
      setShowError(true);
    }
  };
  return (
    <Box
      sx={{
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "2rem",
        pt: 5,
      }}
    >
      Enter your code here
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          pt: 5,
        }}
      >
        <TextField
          label="Code"
          type="text"
          disabled={disabled}
          variant="outlined"
          size="small"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submit();
            }
          }}
          sx={{ fontFamily: "Hanken Grotesk" }}
          onChange={(e) => onChangeOtp(e.target.value)}
        />
        <Button
          variant="contained"
          disabled={disabled}
          onClick={submit}
          sx={{ my: 4 }}
        >
          Submit
        </Button>
      </Box>
      {disabled && !showError && (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          sx={{ width: "100%", pt: 5 }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        sx={{ width: "100%", pt: 5 }}
      >
        {showError && "Invalid Code"}
      </Box>
    </Box>
  );
}

export default Otp;
