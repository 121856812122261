import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";

function GeneralMultiSelect({
  label,
  options,
  onChange,
  includeAllOption = true,
  defaultValue = "",
  required,
  name,
}) {
  const [selectedValue, setSelectedValue] = React.useState(
    defaultValue?.split(",") ?? [],
  );

  const handleChange = (name, value) => {
    const selection = typeof value === "string" ? value.split(",") : value;
    //if selection is array remove ''
    if (selection.includes("")) {
      selection.splice(selection.indexOf(""), 1);
    }
    setSelectedValue(selection);

    if (onChange) {
      onChange(name, selection, "multiSelect");
    }
  };
  return (
    <FormControl sx={{ minWidth: 160, width: "100%" }}>
      <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
      <Select
        labelId={`select-label-${label}`}
        id={`select-${label}`}
        value={selectedValue}
        label={label}
        multiple
        name={name}
        required={required}
        onChange={(e) => handleChange(name, e.target.value)}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => {
          const selectedLabel = selected.map((value) => {
            const option = options.find((opt) => opt.value === value);
            return option ? option.label : value;
          });

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedLabel.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          );
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GeneralMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
};

export default GeneralMultiSelect;
