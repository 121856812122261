import { React, useState, useEffect } from "react";
import GeneralTable from "../components/GeneralTable";
import { getTenantsAPI } from "../api/TenantAPI";
import { CircularProgress, Box } from "@mui/material";

const tableHeaders = [
  {
    id: "ClientID",
    numeric: true,
    disablePadding: true,
    label: "Client ID",
    description: "ID of the client",
  },
  {
    id: "DmId",
    numeric: true,
    disablePadding: true,
    label: "DM ID",
    description: "Dock Master ID of the client",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Test/Live Client",
    description: "Type of the client",
  },
  {
    id: "GatewayType",
    numeric: false,
    disablePadding: true,
    label: "Gateway Type",
    description: "Gateway type of the client",
  },
  {
    id: "ClientName",
    numeric: false,
    disablePadding: true,
    label: "Client Name",
    description: "Name of the client",
  },
  {
    id: "Address1",
    numeric: false,
    disablePadding: true,
    label: "Address",
    description: "Address of the client",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: true,
    label: "City",
    description: "City of the client",
  },
  {
    id: "State",
    numeric: false,
    disablePadding: true,
    label: "State",
    description: "State of the client",
  },
  {
    id: "Zip",
    numeric: true,
    disablePadding: true,
    label: "Zip Code",
    description: "Zip Code of the client",
  },
  {
    id: "Phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
    description: "Phone of the client",
  },
  {
    id: "MobilePhone",
    numeric: false,
    disablePadding: true,
    label: "Mobile Phone",
    description: "Mobile Phone of the client",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    description: "Email of the client",
  },
];

const createElementDialogData = {
  tabs: [
    {
      label: "General Information",
      content: {
        elements: [
          {
            type: "text",
            name: "ClientID",
            label: "Client ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "DmId",
            label: "Dockmaster ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "ClientName",
            label: "Client Name",
            fullWidth: true,
          },
          {
            type: "text",
            name: "Address1",
            label: "Address 1",
            fullWidth: true,
          },
          {
            type: "text",
            name: "Address2",
            label: "Address 2",
            fullWidth: true,
          },
          { type: "text", name: "City", label: "City", fullWidth: true },
          { type: "text", name: "State", label: "State", fullWidth: true },
          { type: "text", name: "Zip", label: "Zip Code", fullWidth: true },
          { type: "text", name: "Country", label: "Country", fullWidth: true },
          { type: "text", name: "HomeUrl", label: "Home Url", fullWidth: true },
          {
            type: "text",
            name: "ContactName",
            label: "Contact Name",
            fullWidth: true,
          },
          { type: "text", name: "Phone", label: "Main Phone", fullWidth: true },
          {
            type: "text",
            name: "MobilePhone",
            label: "Mobile Phone",
            fullWidth: true,
          },
          {
            type: "text",
            name: "Email",
            label: "Email Address",
            fullWidth: true,
          },
          {
            type: "switch",
            name: "PaymentModule",
            label: "Payment Module",
            description:
              "This is the selector indicating that this client is authorized for the DMPay payment module.",
            fullWidth: true,
          },
          {
            type: "switch",
            name: "Disabled",
            label: "Client Disabled",
            description:
              "Toggle this selection to disabled all of this client's accounts applications",
            fullWidth: true,
          },
          {
            type: "text",
            name: "DisabledMessage",
            label: "Client Disabled Message",
            fullWidth: true,
          },
          {
            type: "switch",
            name: "Inactive",
            label: "Client Inactive",
            description:
              "If this field is set to True, The client will be locked out of all applications and functionality.",
            fullWidth: true,
          },
        ],
      },
    },
    {
      label: "Payment Module Control",
      content: {
        elements: [
          {
            type: "switch",
            name: "PayDisabled",
            label: "Payment Disabled",
            description:
              "This is the selector indicating that this payment is disabled.",
            fullWidth: true,
          },
          {
            type: "text",
            name: "PayDisabledMessage",
            label: "Payments Disabled Message",
            fullWidth: true,
          },
          {
            type: "switch",
            name: "PayTestMode",
            label: "Payment Test Mode",
            description:
              "This is the selector indicating that this payment is disabled.",
            fullWidth: true,
          },
          {
            type: "text",
            name: "AnetTranId",
            label: "Authorize.net Tran ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "AnetTranKey",
            label: "Authorize.net Tran Key",
            fullWidth: true,
          },
          {
            type: "text",
            name: "AnetClientKey",
            label: "Authorize.net Client Key",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestAnetTranId",
            label: "Test Authorize.net Tran ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestAnetTranKey",
            label: "Test Authorize.net Tran Key",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestAnetClientKey",
            label: "Test Authorize.net Client Key",
            fullWidth: true,
          },

          {
            type: "text",
            name: "WpAccountId",
            label: "Worldpay Account ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "WpToken",
            label: "Worldpay Token",
            fullWidth: true,
          },
          {
            type: "text",
            name: "WpAcceptorId",
            label: "Worldpay Acceptor ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestWpAccountId",
            label: "Test Worldpay Account ID",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestWpToken",
            label: "Test Worldpay Token",
            fullWidth: true,
          },
          {
            type: "text",
            name: "TestWpAcceptorId",
            label: "Test Worldpay Acceptor ID",
            fullWidth: true,
          },
        ],
      },
    },
  ],
};

export default function ClientMaintenance() {
  const [data, setData] = useState([]);
  const generateToolbar = true;
  const [loading, setLoading] = useState(true);
  const serviceType = "clientMaintenance";
  const itemID = "ClientID";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getTenantsAPI();
        setData(users);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GeneralTable
      data={data}
      itemID={itemID}
      tableHeaders={tableHeaders}
      generateToolbar={generateToolbar}
      createElementDialogData={createElementDialogData}
      serviceType={serviceType}
    />
  );
}
