import axiosInstance from "../helpers/axiosInstance";

const createPaymentSessionAPI = async (paymentSession) => {
  try {
    const response = await axiosInstance.post(
      `adyen/createpaysession`,
      paymentSession,
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getNotificationsAPI = async (filters) => {
  try {
    const response = await axiosInstance.get(`adyen/queryAdyenNotifications/`, {
      params: filters,
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const reverseAdyenPaymentAPI = async (psp_number, merchant_account, tenant_id) => {
  try {
    const response = await axiosInstance.post(
      `adyen/reversePayment/`,
      {},
      {
        params: {
          psp_number,
          merchant_account,
          tenant_id
        },
      },
    );
    if(response.data.data){    
      return response.data.data;
    } 
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};
export { createPaymentSessionAPI, getNotificationsAPI, reverseAdyenPaymentAPI };
