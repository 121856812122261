import axiosInstance from "../helpers/axiosInstance";
import axios from "axios";
import { getAuthCookies } from "../context/CookieManager";

const checkJWt = async (payload) => {
  try {
    const bearer = getAuthCookies("accessToken");
    const response = await axiosInstance.post(
      `auth/check-token-validity`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};
const checkCodeValidity = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACK_END_URL}auth/payment-access-token`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

export { checkJWt, checkCodeValidity };
