import { React, useState, useEffect, useMemo } from "react";
import { getPaymentRequests } from "../api/PaymentRequestAPI";
import { getDepositTypes } from "../api/DepositTypeAPI";
import { getAuthCookies } from "../context/CookieManager";
import { getTenantAPI } from "../api/TenantAPI";
import GeneralTable from "../components/GeneralTable";
import { CircularProgress, Box } from "@mui/material";

const paymentStatus = [
  { value: "PAID", label: "PAID" },
  { value: "PARTIALLY PAID", label: "PARTIALLY PAID"},
  { value: "UNPAID", label: "UNPAID" },
  { value: "FAILED", label: "FAILED" },
];

const tableHeaders = [
  {
    id: "shortId",
    numeric: false,
    disablePadding: true,
    label: "ID",
    description: "Unique identifier of the payment request",
  },
  {
    id: "client_id",
    numeric: false,
    disablePadding: false,
    label: "Client ID",
    description: "Identifier of the client",
  },
  {
    id: "locationid",
    numeric: false,
    disablePadding: false,
    label: "Location ID",
    description: "Location identifier of the request",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Date",
    description: "Date of the request",
  },
  {
    id: "paymentstatus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
    description: "Status of the payment",
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "Customer ID",
    description: "Customer identifier",
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    description: "First name of the customer",
  },
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    description: "Last name of the customer",
  },
  {
    id: "deposittype",
    numeric: false,
    disablePadding: false,
    label: "Deposit Type",
    description: "Type of the deposit",
  },
  {
    id: "totalamount",
    numeric: true,
    disablePadding: false,
    label: "Requested Total Amount",
    description: "Total amount requested",
  },
  {
    id: "depositamount",
    numeric: true,
    disablePadding: false,
    label: "Deposit Amount",
    description: "Deposit amount requested",
  },
];

const generateRightDrawer = {
  header: "Payment Details",
  serviceType: "PaymentRequest",
  fields: [
    { key: "firstname", label: "First name" },
    { key: "lastname", label: "Last name" },
    { key: "primaryemail", label: "Primary Email" },
    { key: "cellphonenumber", label: "Cell Phone" },
  ],
  tabs: [
    {
      label: "Highlighted Info",
      data: [
        { id: "id", label: "ID" },
        { id: "firstname", label: "First Name" },
        { id: "lastname", label: "Last Name" },
        { id: "client_id", label: "Client ID" },
        { id: "primaryemail", label: "Primary Email" },
        { id: "cellphonenumber", label: "Cell Phone Number" },
      ],
    },
    {
      label: "Basic Info",
      data: [
        { id: "id", label: "ID" },
        { id: "client_id", label: "Client ID" },
        { id: "date", label: "Date & Time" },
        { id: "locationid", label: "Location ID" },
      ],
    },
    {
      label: "Communication & Docs",
      data: [
        { id: "firstname", label: "First Name" },
        { id: "lastname", label: "Last Name" },
        { id: "address", label: "Address" },
        { id: "city", label: "City" },
        { id: "state", label: "State" },
        { id: "zip", label: "ZIP Code" },
        { id: "country", label: "Country" },
        { id: "primaryemail", label: "Primary Email" },
        { id: "cellphonenumber", label: "Cell Phone Number" },
        { id: "carrier", label: "Carrier" },
        { id: "subject", label: "Subject" },
        { id: "message", label: "Message" },
        { id: "htmlbody", label: "HTML Body" },
        { id: "referenceNumber", label: "Reference Number" },
      ],
    },
    {
      label: "Payment Status",
      data: [
        { id: "paymentstatus", label: "Payment Status" },
        { id: "confirmationstatus", label: "Confirmation Status" },
        { id: "confirmationreceipt", label: "Confirmation Receipt" },
        { id: "deposittype", label: "Deposit Type" },
        { id: "depositamount", label: "Deposit Amount" },
        { id: "totalamount", label: "Total Amount" },
      ],
    },
  ],
};

export default function PaymentRequest() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const generateToolbar = true;
  const itemID = "id";
  const serviceType = "paymentRequest";
  const editButton = false;
  const [depositTypesOptions, setDepositTypesOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [clientID, setClientID] = useState("");

  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setFilters({});
  };

  useEffect(() => {
    const fetchClientID = async () => {
      const user_id = getAuthCookies("tenantId");
      const tenant_info = await getTenantAPI(user_id);
      setClientID(user_id); // Assuming clientID is part of tenant_info
    };

    fetchClientID();
  }, []);

  useEffect(() => {
    if (clientID) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const paymentRequests = await getPaymentRequests(filters, clientID);
          setData(paymentRequests.data ? paymentRequests.data : []);
        } catch (error) {
          console.error("Failed to fetch payment requests:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [filters, clientID]);

  useEffect(() => {
    const fetchDepositTypes = async () => {
      try {
        const depositTypesData = await getDepositTypes();
        const formattedDepositTypes = depositTypesData.data.map((item) => ({
          value: item.id,
          label: item.description,
          description: item.description,
        }));
        setDepositTypesOptions(formattedDepositTypes);
      } catch (error) {
        console.error("Failed to fetch deposit types:", error);
        setDepositTypesOptions([]);
      }
    };

    fetchDepositTypes();
  }, []);

  const filterConfigs = useMemo(
    () => [
      {
        id: "firstname",
        label: "First Name",
        type: "text",
      },
      {
        id: "totalamount",
        label: "Total Amount",
        type: "text",
      },
      {
        id: "depositamount",
        label: "Deposit Amount",
        type: "text",
      },
      {
        id: "paymentstatus",
        label: "Payment Status",
        type: "select",
        options: paymentStatus,
      },
      {
        id: "created_at",
        label: "Date Created",
        type: "date",
        options: paymentStatus,
      },
      {
        id: "deposittype",
        label: "Deposit Type",
        type: "select",
        options: depositTypesOptions,
      },
    ],
    [depositTypesOptions],
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GeneralTable
      data={data}
      itemID={itemID}
      generateToolbar={generateToolbar}
      generateRightDrawer={generateRightDrawer}
      tableHeaders={tableHeaders}
      serviceType={serviceType}
      editButton={editButton}
      filterConfigs={filterConfigs}
      onFilterChange={handleFilterChange}
      deleteButton={false}
      applyFilters={applyFilters}
      resetFilters={resetFilters}
    />
  );
}
