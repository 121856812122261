export class CustomerReservation {
  constructor(data) {
    this.id = data.id || data.ID || "";
    this.customerId = data.customerId || data.CustomerId || "";
    this.firstName = (data.firstName || data.FirstName || "").trim();
    this.lastName = (data.lastName || data.LastName || "").trim();
    this.homePhone = data.homePhone || data.HomePhone || "";
    this.address1 = data.address1 || data.Address1 || "";
    this.city = data.city || data.City || "";
    this.state = data.state || data.State || "";
    this.zip = data.zip || data.Zip || "";
    this.country = data.country || data.Country || "";
    this.boatId = data.boatId || data.BoatId || "";
    this.boatName = data.boatName || data.BoatName || "";
    this.boatLength = data.boatLength || data.BoatLength || "";
    this.boatDraft = data.boatDraft || data.BoatDraft || "";
    this.boatBeam = data.boatBeam || data.BoatBeam || "";
    this.arrivalDate = data.arrivalDate || data.ArrivalDate || "";
    this.departureDate = data.departureDate || data.DepartureDate || "";
    this.slipId = data.slipId || data.SlipId || "";
    this.locationCode = data.locationCode || data.LocationCode || "";
    this.confirmed = data.confirmed || data.Confirmed || false;
    this.generalComments = data.generalComments || data.GeneralComments || "";
    this.billCodes = Array.isArray(data.billCodes) ? data.billCodes : (Array.isArray(data.BillCodes) ? data.BillCodes : []);
  }
}
