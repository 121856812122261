export class PaymentRequest {
    constructor(data) {
        this.id = data.id || '';
        this.client_id = data.client_id || null;
        this.requesttype = data.requesttype || '';
        this.firstname = data.firstname || '';
        this.lastname = data.lastname || '';
        this.address = data.address || '';
        this.city = data.city || '';
        this.state = data.state || '';
        this.zip = data.zip || '';
        this.country = data.country || '';
        this.primaryemail = data.primaryemail || null;
        this.otheremailsArray = data.otheremailsArray || [];
        this.cellphonenumber = data.cellphonenumber || null;
        this.carrier = data.carrier || null;
        this.subject = data.subject || null;
        this.message = data.message || null;
        this.htmlbody = data.htmlbody || null;
        this.depositamount = data.depositamount || null;
        this.deposittype = data.deposittype || null;
        this.totalamount = data.totalamount || 0;
        this.locationid = data.locationid || null;
        this.paymentstatus = data.paymentstatus || null;
        this.date = data.date || null;
        this.confirmationstatus = data.confirmationstatus || null;
        this.confirmationreceipt = data.confirmationreceipt || null;
        this.referenceNumber = data.referenceNumber || null;
        this.customerId = data.customerId || null;
        this.additional_data = data.additional_data || null;
        this.tenant_info = data.tenant_info || null;
        this.created_at = data.created_at || null;
        
        this.shortId = '...' + (data.id.length > 6 ? this.id.slice(-6) : this.id);
    }
}
