import { React, useState, useEffect } from "react";
import { getSecurityGroups } from "../api/SecurityGroupsAPI";
import GeneralTable from "../components/GeneralTable";
import { CircularProgress, Box } from "@mui/material";

const tableHeaders = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Group Name",
    description: "Name of the group",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Group Description",
    description: "Description of the group",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    description: "Type of the group",
  },
  {
    id: "num_users",
    numeric: true,
    disablePadding: true,
    label: "# of Users",
    description: "Number of users in a group",
  },
  {
    id: "menuitems",
    numeric: true,
    disablePadding: true,
    label: "Menu Items",
    description: "Menu items in a group",
  },
];

const serviceType = "securityGroup";

const generateRightDrawer = {
  buttonText: "Update",
  header: "Security Group",
  serviceType: serviceType,
  tabs: [
    {
      label: "General Information",
      data: [
        { type: "text", id: "name", label: "Group Name" },
        { type: "text", id: "description", label: "Group Description" },
        { type: "text", id: "type", label: "Group Type" },
        { type: "text", id: "num_users", label: "Number of users" },
        {
          type: "multiSelect",
          name: "menuitems",
          id: "menuitems",
          label: "Menu Items",
          includeAllOption: false,
          fullWidth: true,
        },
      ],
    },
  ],
};

export default function SecurityGroups() {
  const [securityGroups, setSecurityGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const generateToolbar = true;
  const itemID = "name";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSecurityGroups();
        setSecurityGroups(response.data);
      } catch (error) {
        console.error("Failed to fetch securiryt groups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const createElementDialogData = {
    tabs: [
      {
        label: "General Information",
        content: {
          elements: [
            { type: "text", name: "id", label: "ID", fullWidth: true },
            {
              type: "text",
              name: "description",
              label: "Description",
              fullWidth: true,
            },
            {
              type: "switch",
              name: "dmonly",
              label: "DM Only",
              description: "DM Only",
              fullWidth: true,
            },
            {
              type: "multiSelect",
              name: "menuitems",
              id: "menuitems",
              label: "Menu Items",
              includeAllOption: false,
              fullWidth: true,
            },
          ],
        },
      },
    ],
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GeneralTable
      data={securityGroups}
      itemID={itemID}
      tableHeaders={tableHeaders}
      generateToolbar={generateToolbar}
      createElementDialogData={createElementDialogData}
      serviceType={serviceType}
      generateRightDrawer={generateRightDrawer}
    />
  );
}
