import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import { useState } from "react";

export default function FormDialog({ formDialogState, handleOpen, submit }) {
  const [fields, setFields] = useState([
    {
      name: "field-0",
      value: formDialogState.existingData,
    },
  ]);
  const [subject, setSubject] = useState("");
  const [error, setError] = useState(false);
  const [requiredError, setRequiredError] = useState("");
  const handleSubmit = (e) => {
    setError(false);
    setRequiredError(false);
    //if all fields are empty show error
    const value = fields.map((field) => field.value).join("");
    if (value === "") {
      setError(true);
      return;
    }
    if (formDialogState.type === "email" && subject === "") {
      setRequiredError(true);
      return;
    }

    //remove empty fields and return array of values only
    const validEmails = fields
      .filter((field) => field.value !== "")
      .map((field) => field.value);

    submit(
      formDialogState.type,
      validEmails,
      formDialogState.customerId,
      formDialogState.fullName,
      subject,
    );
    handleOpen();
  };

  const addNewField = () => {
    setFields([...fields, { name: `field-${fields.length}`, value: "" }]);
  };

  const handleChange = (event, index) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };
  return (
    <>
      <Dialog
        open={formDialogState.open}
        onClose={handleOpen}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
          },
        }}
      >
        <DialogTitle>
          Missing {formDialogState.type === "email" ? "Email" : "Phone Number"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            please enter a valid {formDialogState.type} to continue
          </DialogContentText>

          {formDialogState.type == "email" && (
            <TextField
              autoFocus
              required
              margin="dense"
              name={"subject"}
              label={"Subject"}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              type={"text"}
              value={subject}
              variant="standard"
            />
          )}

          {fields.map((field, index) => (
            <TextField
              autoFocus
              required
              margin="dense"
              name={field.name}
              label={
                formDialogState.type === "email" ? "Email" : "Phone Number"
              }
              onChange={(e) => handleChange(e, index)}
              fullWidth
              type={formDialogState.type === "email" ? "text" : "number"}
              value={field.value}
              variant="standard"
            />
          ))}
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={addNewField}
              color="primary"
            >
              Add
              <AddIcon />
            </Button>
          </Box>
          <Box sx={{ color: "red", mt: 1 }}>
            {error &&
              `Please enter a valid ${
                formDialogState.type === "email" ? "email" : "phone number"
              }`}
            {requiredError && `Please make sure to fill all fields`}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>Cancel</Button>
          <Button onClick={handleSubmit}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
