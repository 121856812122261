export class CustomerContract {
    constructor(data) {
        this.contractId = data.contractId || data.ContractId || '';
        this.customerId = data.customerId || data.CustomerId || '';
        this.prospectId = data.prospectId || data.ProspectId || '';
        this.isQuote = data.isQuote || data.IsQuote || false;
        this.name = data.name || data.Name || '';
        this.salesMan = data.salesMan || data.SalesMan || '';
        this.locationCode = data.locationCode || data.LocationCode || '';
        this.salesLocation = data.salesLocation || data.SalesLocation || '';
        this.salesDate = data.salesDate || data.SalesDate || '';
        this.writtenDate = data.writtenDate || data.WrittenDate || '';
        this.taxSchema = data.taxSchema || data.TaxSchema || '';
        this.thirdPartyIdentifier = data.thirdPartyIdentifier || data.ThirdPartyIdentifier || '';
        this.totalPrices = data.totalPrices || data.TotalPrices || 0;
        this.contractTotal = data.contractTotal || data.ContractTotal || 0;
        this.tradeItems = data.tradeItems ? data.tradeItems.map(item => new TradeItem(item)) : (data.TradeItems ? data.TradeItems.map(item => new TradeItem(item)) : []);
        this.units = data.units || data.Units || [];
    }
}

class TradeItem {
    constructor(item) {
        this.tradeId = item.tradeId || item.TradeId || '';
        this.type = item.type || item.Type || '';
        this.vendorId = item.vendorId || item.VendorId || '';
        this.vendorName = item.vendorName || item.VendorName || '';
        this.modelNumber = item.modelNumber || item.ModelNumber || '';
        this.modelDescription = item.modelDescription || item.ModelDescription || '';
        this.year = item.year || item.Year || '';
        this.modelType = item.modelType || item.ModelType || '';
        this.serialNumber = item.serialNumber || item.SerialNumber || '';
        this.stockNumber = item.stockNumber || item.StockNumber || '';
        this.registration = item.registration || item.Registration || '';
        this.color = item.color || item.Color || '';
        this.department = item.department || item.Department || '';
        this.classCode = item.classCode || item.ClassCode || '';
        this.balancePaidBy = item.balancePaidBy || item.BalancePaidBy || '';
        this.lienHolder = item.lienHolder || item.LienHolder || '';
        this.lienHolderContact = item.lienHolderContact || item.LienHolderContact || '';
        this.lienHolderAddress1 = item.lienHolderAddress1 || item.LienHolderAddress1 || '';
        this.lienHolderAddress2 = item.lienHolderAddress2 || item.LienHolderAddress2 || '';
        this.lienHolderAddress3 = item.lienHolderAddress3 || item.LienHolderAddress3 || '';
        this.lienHolderCity = item.lienHolderCity || item.LienHolderCity || '';
        this.lienHolderState = item.lienHolderState || item.LienHolderState || '';
        this.lienHolderPostalCode = item.lienHolderPostalCode || item.LienHolderPostalCode || '';
        this.lienHolderPhoneNumber = item.lienHolderPhoneNumber || item.LienHolderPhoneNumber || '';
        this.lienHolderAccountNumber = item.lienHolderAccountNumber || item.LienHolderAccountNumber || '';
        this.boatKeyNumber = item.boatKeyNumber || item.BoatKeyNumber || '';
        this.boatHullType = item.boatHullType || item.BoatHullType || '';
        this.boatHullMaterial = item.boatHullMaterial || item.BoatHullMaterial || '';
        this.boatLength = item.boatLength || item.BoatLength || '';
        this.boatBeam = item.boatBeam || item.BoatBeam || '';
        this.boatWeight = item.boatWeight || item.BoatWeight || '';
        this.boatName = item.boatName || item.BoatName || '';
        this.motorOutDrive = item.motorOutDrive || item.MotorOutDrive || '';
        this.motorTransom = item.motorTransom || item.MotorTransom || '';
        this.motorPowerRating = item.motorPowerRating || item.MotorPowerRating || '';
        this.motorShaftLength = item.motorShaftLength || item.MotorShaftLength || '';
        this.motorCarberation = item.motorCarberation || item.MotorCarberation || '';
        this.motorStrokes = item.motorStrokes || item.MotorStrokes || '';
        this.trailerMaterials = item.trailerMaterials || item.TrailerMaterials || '';
        this.trailerTireSize = item.trailerTireSize || item.TrailerTireSize || '';
        this.trailerAxlesBrakes = item.trailerAxlesBrakes || item.TrailerAxlesBrakes || '';
        this.trailerLength = item.trailerLength || item.TrailerLength || '';
        this.trailerWidth = item.trailerWidth || item.TrailerWidth || '';
        this.trailerWeight = item.trailerWeight || item.TrailerWeight || '';
        this.trailerCapacity = item.trailerCapacity || item.TrailerCapacity || '';
        this.trailerBallDiameter = item.trailerBallDiameter || item.TrailerBallDiameter || '';
    }
}
