import { BASE_URL } from "../config";
import { User as UserModel } from "../models/User";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getUserAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`users/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get('users/self');
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || "There was a problem with the Axios operation."
    );
  }
};

const getUser = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/users/${id}`);
    const data = new UserModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getUsers = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/users/all`);

    const data = response.data.data.map((item) => {
      const status = item.inactive ? "Inactive" : "Active";
      const userWithStatus = { ...item, status };
      return new UserModel(userWithStatus);
    });
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      [],
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getUsersWithTenantID = async (tenant_id) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/users/tenant/${tenant_id}`,
    );

    const data = response.data.data.map((item) => {
      const status = item.inactive ? "Inactive" : "Active";
      const userWithStatus = { ...item, status };
      return new UserModel(userWithStatus);
    });
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      [],
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const createUser = async (newUser) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/users/register`,
      newUser,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const data = new UserModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "User created successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.message || "Failed to create user due to a network or parsing error.",
    );
  }
};

const updateUser = async (id, updatedUser) => {
  if (!updatedUser.inactive) {
    updatedUser.inactive = false;
  }
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/users/update/${id}`,
      updatedUser,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const data = new UserModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "User updated successfully",
    );
  } catch (error) {
    console.error("There was a problem with the update operation:", error);
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the update operation.",
    );
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/users/delete/${id}`,
    );

    return new StandardResponse(
      null,
      response.data.status_code,
      "User deleted successfully",
    );
  } catch (error) {
    console.error("There was a problem with the delete operation:", error);
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the delete operation.",
    );
  }
};

const updateUserAPI = async (id, updatedUser) => {
  try {
    const response = await axiosInstance.put(`users/update/${id}`, updatedUser);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const resetPasswordAPI = async (password) => {
  try {
    const response = await axiosInstance.post(`users/reset-password`, {
      password: password,
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

export {
  getUser,
  getCurrentUser,
  getUsers,
  getUsersWithTenantID,
  createUser,
  updateUser,
  deleteUser,
  updateUserAPI,
  getUserAPI,
  resetPasswordAPI,
};
