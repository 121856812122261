import React from "react";
import FormDialogElement from "./FormDialogElement";

const UpdateElement = ({
  serviceType,
  tabData,
  data,
  onSubmit,
  buttonText,
  fetchedOptions,
}) => {
  const elements = tabData.map(({ type, id, label, ...extraProps }) => ({
    type,
    name: id,
    label,
    placeholder: label,
    value: data[id],
    fullWidth: true,
    ...extraProps,
  }));

  return (
    <FormDialogElement
      serviceType={serviceType}
      elements={elements}
      buttonText={buttonText}
      onSubmit={onSubmit}
      prefetchedOptions={fetchedOptions}
      createElement={false}
    />
  );
};

export default UpdateElement;
