import {
  Box,
  TextField,
  Button,
  Collapse,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { reversePayment } from "../../api/DockMasterAPI";
import { updatePaymentAPI } from "../../api/PaymentAPI";
import { reverseAdyenPaymentAPI } from "../../api/Adyen";
import {getCurrentUser} from "../../api/UsersAPI";

const RowDetails = ({
  row,
  expandedRow,
  tabValue,
  handleChange,
  handleOpen,
  canVoidRefund,
  handleSaveComment,
  rowData,
  TabPanel,
  setRowData,
}) => {
  const handleVoidRefund = async () => {
    try {
      // Split the batch IDs and reference numbers if they contain multiple entries
      const batchIds = row.batch_id ? row.batch_id.split(', ') : [null];
      const referenceNumbers = row.reference_number ? row.reference_number.split(', ') : [null];
  
      if (batchIds[0] === null && referenceNumbers[0] === null) {
        // Only Adyen reversal needed as there are no batch IDs or reference numbers
      } else {
        // Check matching counts of batch IDs and reference numbers
        if (batchIds.length !== referenceNumbers.length) {
          throw new Error("Mismatched batch IDs and reference numbers.");
        }
    
        // Process each batch ID and reference number pair
        for (let i = 0; i < batchIds.length; i++) {
          const result = await reversePayment(
            row.client_id,
            row.customer_id,
            batchIds[i],
            referenceNumbers[i],
            row.refund_reason ?? "refund",
          );
    
          if (result.status_code !== 200) {
            throw new Error("Failed to reverse/refund payment for batch " + batchIds[i]);
          }
        }
      }
      
      let jsonResponse = await updatePaymentAPI(row.id, {
        payment_state: "REFUND REQUESTED",
      });
      alert("Payment reversal/refund requested successfully.");

      // Call Adyen reversal API only once per payment
      const adyenResponse = await reverseAdyenPaymentAPI(
        row.pspReference,
        JSON.parse(row.adyen_payment_response)[0].merchantAccountCode,
        row.client_id
      );
  
      // Update the payment state after all reversals are processed
      jsonResponse = await updatePaymentAPI(row.id, {
        payment_state: "REFUNDED",
      });
  
      alert("Payment reversed/refunded successfully.");
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert("Something went wrong: " + error.message);
    }
  };
  
  
  const tabData = [
    { data: row.adyen_payment_payload, label: "Payment Payload" },
    { data: row.adyen_payment_response, label: "Payment Response" },
    { data: row.dockmaster_payment_payload, label: "API Payload" },
    { data: row.dockmaster_payment_response, label: "API Response" },
  ];
  return (
    <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
      <Box margin={1} sx={{ width: "50%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Row Details Tabs"
              variant="standard"
            >
              {tabData.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
            {tabData.map((tab, index) => (
              <TabPanel key={index} value={tabValue} index={index}>
                <Box
                  sx={{ width: "600px", overflow: "scroll" }}
                  onClick={() => handleOpen(tab.data)}
                >
                  {tab.data || "No data"}
                </Box>
              </TabPanel>
            ))}
          </Box>
          <Box>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Payment Processor"
                  secondary={row.paymentProcessor || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Pay Type"
                  secondary={row.payType || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Account Number"
                  secondary={row.accountNumber || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Auth Code"
                  secondary={row.authCode || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="PSP Reference"
                  secondary={row.pspReference || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Trans Status"
                  secondary={row.transStatus || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Request Link Code"
                  secondary={row.requestLinkCode || "N/A"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Location"
                  secondary={row.location || "N/A"}
                />
              </ListItem>
            </List>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleVoidRefund}
              disabled={
                row.payment_state !== "PAID" && (
                  !canVoidRefund ||               // Button is disabled if canVoidRefund is false
                  row.payment_state === "REFUNDED" || row.payment_state === "REFUND REQUESTED" || // Button is disabled if payment_state is "REFUNDED" or "REFUND REQUESTED"
                  (row.payment_status === "UNPROCESSED" && row.payment_state === "PENDING") // Button is disabled if payment_status is "UNPROCESSED" and payment_state is "PENDING"
                )
              }
            >
              Void/Refund Payment
            </Button>
            <TextField
              size="small"
              variant="outlined"
              type="text"
              rows={4}
              name="comment"
              value={rowData?.comment ?? ""}
              onChange={(e) => {
                setRowData((prev) => ({ ...prev, comment: e.target.value }));
              }}
              placeholder="Comment"
              sx={{ my: 2 }}
              multiline
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSaveComment}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Collapse>
  );
};

export default RowDetails;
