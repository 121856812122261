import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import DockMasterImage from "../assets/DockMaster-Logo-White.png";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Cookies from "js-cookie";
import { useAuth } from "../context/AuthContext";
import { Select } from "@mui/material";
import { getAuthCookies } from "./../context/CookieManager";
import { useEffect } from "react";
import { getClientCredentialsAPI } from "../api/CredentialsAPI";
import { setCurrentSystemIdAPI } from "../api/DockMasterAPI";
import { useState } from "react";
import { updateUserAPI, getUserAPI, resetPasswordAPI } from "../api/UsersAPI";
import ProfileDialog from "./../components/dialog/ProfileDialog";

function Header() {
  const { logout } = useAuth();
  const [TenantCredentials, setTenantCredentials] = useState(null);
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const user = {
    username: Cookies.get("username") || "Guest",
    port: "Test Marina",
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
    handleCloseUserMenu();
  };

  const handleOpenProfileModal = () => {
    handleCloseUserMenu();
    setOpenDialog(true);
  };

  const handleChangeTenant = (e) => {
    const value = e.target.value;
    setSelectedSystemId(value);
    syncSystemId(value);
  };

  const changePassword = async (password) => {
    try {
      await resetPasswordAPI(password);
      setOpenDialog(false);
      setSnackbarMessage("Password changed successfully");
    } catch (error) {
      setSnackbarMessage("Error changing password");
      console.log("eerror", error);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const syncSystemId = async (systemId) => {
    try {
      const tenant = getAuthCookies("tenantId");
      const user = getAuthCookies("userId");

      await setCurrentSystemIdAPI(tenant, systemId);
      await updateUserAPI(user, {
        current_system_id: systemId,
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  const settings = [
    { label: "Profile", action: handleOpenProfileModal },
    { label: "Logout", action: handleLogout },
  ];
  const isAdmin = getAuthCookies("securityGroup") === "Administration";

  useEffect(() => {
    collectUserData();
  }, []);

  const collectUserData = async () => {
    try {
      const tenant = getAuthCookies("tenantId");
      const user = getAuthCookies("userId");

      if (tenant && user) {
        const [tenantCredentials, userResponse] = await Promise.all([
          getClientCredentialsAPI(tenant),
          getUserAPI(user),
        ]);
        setTenantCredentials(tenantCredentials[0] ?? null);
        setSelectedSystemId(
          userResponse.current_system_id ??
            tenantCredentials[0].DefaultSystemId,
        );
        Cookies.set(
          "voidRefundPermission",
          userResponse.void_refund_permission,
        );
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#0F1F2E" }}>
      <Box sx={{ mx: 5 }}>
        <Toolbar disableGutters>
          <img
            style={{ width: "300px", marginRight: "10px" }}
            alt="icon"
            src={DockMasterImage}
          />

          <Typography
            variant="body1"
            noWrap
            sx={{
              fontFamily: "Hanken Grotesk",
            }}
          >
            CLICK TO PAY Administration
          </Typography>

          {/* Spacer */}
          <Box sx={{ flexGrow: 1 }}></Box>
          {!isAdmin && (
            <Box sx={{ mx: 3 }}>
              <Select
                sx={{
                  width: "10rem",
                  background: "white",
                }}
                name="connection"
                size="small"
                value={selectedSystemId}
                onChange={handleChangeTenant}
                disabled
              >
                {TenantCredentials?.SystemIdList?.map((system) => (
                  <MenuItem key={system.systemId} value={system.systemId}>
                    {system.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          {/* user information and settings icon */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                fontFamily: "Hanken Grotesk",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                mr: 2,
              }}
            >
              {/* user information */}
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontSize: "0.85rem",
                }}
              >
                {user.username}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#D0D5DD",
                  fontSize: "0.6rem",
                }}
              >
                {user.port}
              </Typography>
            </Box>
            {/* settings icon */}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Settings Menu */}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                sx={{ width: "150px" }}
                key={setting.label}
                onClick={setting.action || handleCloseUserMenu}
              >
                <Typography textAlign="center">{setting.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Box>
      {openDialog && (
        <ProfileDialog
          formDialogState={{
            open: openDialog,
          }}
          handleOpen={() => setOpenDialog((prev) => !prev)}
          submit={changePassword}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </AppBar>
  );
}

export default Header;
