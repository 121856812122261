import React, { useState } from "react";
import { Box } from "@mui/material";
import DockMasterImage from "../../assets/DockMaster-Logo.png";
import FormDialogElement from "../form/FormDialogElement";
import { LoginUser } from "../../api/Login";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

const LoginForm = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(false);

  const handleSubmit = async (event) => {
    try {
      setError(false);
      const response = await LoginUser(event.username, event.password);
      if (response.status_code === 200) {
        login(response.data, event.username);
        navigate("/admin");
      }
    } catch (error) {
      setError(true);
    }
  };

  const elements = [
    {
      type: "text",
      name: "username",
      label: "Username",
      placeholder: "Enter Username",
      value: "",
      fullWidth: true,
      required: true,
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter Password",
      value: "",
      fullWidth: true,
      required: true,
    },
  ];

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: "50%", md: "25%" },
        }}
      >
        <img
          src={DockMasterImage}
          alt="Dock Master"
          style={{ width: "100%" }}
        />
        <Typography
          variant="body1"
          noWrap
          sx={{
            mb: 1,
            fontSize: "1.5rem",
            textAlign: "center",
            mt: 5,
            fontFamily: "Hanken Grotesk",
          }}
        >
          CLICK TO PAY Administration
        </Typography>
        <FormDialogElement
          serviceType={"login"}
          elements={elements}
          buttonText={"login"}
          onSubmit={handleSubmit}
        />
        {error && (
          <Box
            sx={{
              color: "red",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              mt: 2,
            }}
          >
            Wrong Username or Password. Please try again.
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LoginForm;
