import { BASE_URL } from "../config";
import { MenuItem as MenuItemModel } from "../models/MenuItem";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getMenuItemAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`menu_items/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getMenuItems = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/menu_items/`);
    const data = response.data.data.map((item) => new MenuItemModel(item));
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const createMenuItem = async (newMenuItem) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/menu_items/`,
      newMenuItem,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    const data = new MenuItemModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Menu item created successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};
const updateMenuItem = async (updatedMenuItem) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/menu_items/${updatedMenuItem.id}/`,
      updatedMenuItem,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
    const data = new MenuItemModel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Menu item updated successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const deleteMenuItem = async (id) => {
  try {
    const response = await axiosInstance.delete(`${BASE_URL}/menu_items/${id}`);
    return new StandardResponse(
      null,
      response.data.status_code,
      "Menu item deleted successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

export {
  getMenuItemAPI,
  getMenuItems,
  createMenuItem,
  updateMenuItem,
  deleteMenuItem,
};
