import React, {useState} from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const GeneralCheckbox = ({ name, label, required, onChange, initialValue=false,  ...props }) => {
  const [checked, setChecked] = useState(initialValue);

  const handleCheckChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        required={required}
        control={
          <Checkbox
            name={name}
            checked={checked}
            onChange={handleCheckChange}
            {...props}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default GeneralCheckbox;
