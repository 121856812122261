import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const DataColumnView = ({ data, tabData }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarMessage("ID copied to clipboard!");
      setSnackbarOpen(true);
    }).catch(err => {
      setSnackbarMessage("Failed to copy ID to clipboard.");
      setSnackbarOpen(true);
      console.error('Failed to copy text to clipboard', err);
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const formatValue = (id, value) => {
    if (id === 'totalamount' || id === 'depositamount') {
      return `$ ${Number(value ?? 0).toFixed(2)}`;
    }
    return value;
  };

  return (
    <Box>
      {tabData.map((header) => (
        <Box 
          key={header.id} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2,
          }}
        >
          <Typography 
            variant="body1" 
            component="span" 
            sx={{ 
              fontWeight: 'bold', 
              fontFamily: 'Hanken Grotesk',
              color: '#344054'
            }}
          >
            {header.label}:
          </Typography>

          <Typography 
            variant="body2" 
            component="span" 
            sx={{ 
              fontFamily: 'Hanken Grotesk',
              color: '#101828' 
            }}
          >
            {formatValue(header.id, data[header.id])}
          </Typography>
          {header.id === 'id' && (
              <Button
                size="small"
                onClick={() => handleCopy(data[header.id])}
                sx={{ minWidth: 'auto' }} 
              >
                <ContentCopyIcon fontSize="small" />
              </Button>
            )}
        </Box>
      ))}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DataColumnView;
