import { React, useState } from "react";
import GeneralTable from "../components/GeneralTable";
import Box from "@mui/material/Box";
import Switch from "../components/switch/index";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import PrimaryButton from "../components/buttons/PrimaryButton";
import {
  getCustomers,
  getProspects,
  getCustomerInformation,
  getProspectInformation,
} from "../api/DockMasterAPI";
import { getAuthCookies } from "../context/CookieManager";
import { getTenantAPI } from "../api/TenantAPI";

export default function Terminal() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [header, setHeader] = useState([]);

  const [searchOption, setSearchOption] = useState("customers");
  const [defaultPayLoc, setDefaultPayLoc] = useState("customers");
  const [loading, setLoading] = useState(false);

  const deleteButton = false;
  const generateToolbar = false;
  const serviceType = "terminal";

  const [itemID, setItemID] = useState("");
  const [type, setType] = useState("");
  const [method, setMethod] = useState("");

  const TerminalPaymentDialogContent = {
    buttonText: "Create Payment Request",
    tabs: [
      {
        label: "Payment",
        content: {
          elements: [
            {
              type: "select",
              name: "location",
              label: "Location",
              includeAllOption: false,
              fullWidth: true,
              required: true,
            },
            {
              type: "select",
              name: "depositType",
              label: "Invoice/Deposit Type",
              includeAllOption: true,
              fullWidth: true,
              value: "all",
            },
          ],
        },
      },
    ],
    fields: [
      { key: type === "customers" ? "name" : "fullName", label: "Name" },
      { key: type === "customers" ? "email" : "emailAddress", label: "Email" },
      {
        key: type === "customers" ? "id" : "customerId",
        label: "Customer ID",
      },
      { key: "prospectId", label: "Prospect ID" },
      { key: type === "customers" ? "address1" : "address", label: "Location" },
    ],
  };

  const handleSearch = async (event) => {
    try {
      if (searchTerm === "") {
        return;
      }
      setLoading(true);

      const user_id = getAuthCookies("tenantId");
      const tenant_info = await getTenantAPI(user_id);
      setDefaultPayLoc(tenant_info.DefaultPayLoc);

      let fetchedData = [];

      if (searchOption === "customers") {
        setHeader([
          {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "Customer ID",
            description: "Unique ID of the user",
          },
          {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: "Name",
            description: "Name of the User",
          },
          {
            id: "email",
            numeric: true,
            disablePadding: false,
            label: "Email",
            description: "Email of the user",
          },
          {
            id: "address1",
            numeric: false,
            disablePadding: false,
            label: "Address",
            description: "Address of the user",
          },
          {
            id: "companyName",
            numeric: false,
            disablePadding: false,
            label: "CompanyName",
            description: "Company name of the user",
          },
          {
            id: "prospectId",
            numeric: false,
            disablePadding: false,
            label: "Prospect Id",
            description: "Prospect Id of the user",
          },
        ]);
        setType("customers");
        if (isNaN(searchTerm)) {
          setMethod("byName");
          setItemID("id");
          fetchedData = await getCustomers(user_id, searchTerm);
        } else {
          setItemID("id");
          setMethod("byID");
          fetchedData = await getCustomerInformation(user_id, searchTerm);
        }
      } else {
        setType("prospects");
        setHeader([
          {
            id: "prospectId",
            numeric: false,
            disablePadding: true,
            label: "Prospect ID",
            description: "Unique ID of the prospect",
          },
          {
            id: "fullName",
            numeric: false,
            disablePadding: false,
            label: "Name",
            description: "Name of the Prospect",
          },
          {
            id: "salesman",
            numeric: false,
            disablePadding: false,
            label: "Salesman",
            description: "Salesman of the Prospect",
          },
          {
            id: "dateEntered",
            numeric: false,
            disablePadding: false,
            label: "Date Entered",
            description: "DateEntered of the Prospect",
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Status",
            description: "Status of the Prospect",
          },
          {
            id: "emailAddress",
            numeric: false,
            disablePadding: false,
            label: "Email ",
            description: "Email of the Prospect",
          },
          {
            id: "customerId",
            numeric: false,
            disablePadding: false,
            label: "CustomerId",
            description: "CustomerId of the Prospect",
          },
        ]);
        if (isNaN(searchTerm)) {
          setMethod("byName");
          fetchedData = await getProspects(user_id, searchTerm);
          console.log("fetchedData", fetchedData.data[1]);
          setItemID("prospectId");
        } else {
          setMethod("byID");
          fetchedData = await getProspectInformation(user_id, searchTerm);
          console.log("fetchedData by ID", fetchedData.data[0]);
          setItemID("prospectId");
        }
      }
      setData(fetchedData.data);
    } catch (error) {
      console.error("Error performing search:", error);
    } finally {
      setLoading(false);
    }
  };

  const switchHandler = (option) => {
    setSearchOption(option);
    setSearchTerm("");
    setData([]);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          my: 2,
          p: 2,
          border: "1px solid rgba(208, 213, 221, 1)",
          borderRadius: "10px",
        }}
        onSubmit={handleSearch}
      >
        <Switch
          values={["customers", "prospects"]}
          value={searchOption}
          switchHandler={switchHandler}
        />
        <div>
          <Typography variant="body1" sx={{ pb: 1 }}>
            Search
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter ID or name"
            size="small"
            value={searchTerm}
            sx={{ fontFamily: "Hanken Grotesk", background: "#F0F2F5" }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (!loading) handleSearch();
              }
            }}
          />
        </div>
        <PrimaryButton
          disabled={loading}
          type="submit"
          onClick={handleSearch}
          sx={{
            minWidth: "180px",
            padding: "6px 12px",
            fontSize: "0.75rem",
            fontWeight: "600",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Customer Lookup"}
        </PrimaryButton>
      </Box>
      {data.length > 0 && (
        <GeneralTable
          type={type}
          data={data}
          itemID={itemID}
          tableHeaders={header}
          generateToolbar={generateToolbar}
          serviceType={serviceType}
          deleteButton={deleteButton}
          TerminalPaymentDialogContent={TerminalPaymentDialogContent}
          onDataFetch={() => console.log("")}
          DefaultPayLoc={defaultPayLoc}
        />
      )}
    </>
  );
}
