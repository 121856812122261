import * as React from "react";
import { Popper } from "@mui/base/Popper";
import { styled, css } from "@mui/system";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
export default function PaymentPopOver({ disabled, sendRequest }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        sx={{
          background: "#1976d2",
          borderRadius: "8px",
          color: "white",
          "&:hover": {
            backgroundColor: "#1976d2",
            color: "inherit",
          },
        }}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Popper
        placement={"bottom-end"}
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorEl}
      >
        <StyledPopperDiv>
          <Box
            onClick={() => sendRequest("email")}
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(208, 213, 221, 1)",
                color: "white",
              },
              p: "6px 16px 6px 16px",
            }}
          >
            <MailOutlineIcon sx={{ pr: 1 }} />
            <div>Prepare Email Request</div>
          </Box>
          <Box
            onClick={() => sendRequest("sms")}
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(208, 213, 221, 1)",
                color: "white",
              },
              p: "6px 16px 6px 16px",
            }}
          >
            <PhoneIphoneIcon sx={{ pr: 1 }} />
            <div>Prepare Text Request</div>
          </Box>
        </StyledPopperDiv>
      </Popper>
    </>
  );
}

const StyledPopperDiv = styled("div")(
  ({ theme }) => css`
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(208, 213, 221, 1);
    box-shadow: 0px 2px 4px 0px rgba(117, 128, 146, 0.08);
    color: rgba(37, 122, 191, 1);
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    opacity: 1;
    size: 14px;
    margin: 0.25rem 0;
  `,
);
