export class User {
  constructor(data) {
    this.id = data.id || "";
    this.email = data.email || "";
    this.name = data.name || "";
    this.hashed_password = data.hashed_password || "";
    this.inactive = data.inactive || false;
    this.securitygroup = data.securitygroup || null;

    this.status = data.status || "";
    this.payment_status = data.payment_status || "";
    this.permissions = data.permissions || "";
    this.tenant_id = data.tenant_id || "";
    this.void_refund_permission = data.void_refund_permission || "";
  }
}
