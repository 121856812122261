import React, { useEffect } from "react";
import Box from "@mui/material/Box";

function PaymentSuccess() {
  return (
    <Box
      sx={{
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "2rem",
        pt: 5,
        color: "green",
      }}
    >
      Payment Successful
    </Box>
  );
}

export default PaymentSuccess;
