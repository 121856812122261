import React from "react";
import { FormControlLabel, Switch, Box, Typography } from "@mui/material";

const GeneralControlButton = ({
  name,
  label,
  description,
  onChange,
  required,
  ...props
}) => {
  const handleToggle = (event) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        pr: 1,
        pl: 1,
        mt: 2,
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Box>
      <FormControlLabel
        control={<Switch name={name} {...props} onChange={handleToggle} />}
        label=""
        labelPlacement="start"
        required={required}
        sx={{ marginLeft: 0, marginRight: 0 }}
      />
    </Box>
  );
};

export default GeneralControlButton;
