import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

export default function BasicDatePicker({ label, value, onChange }) {
    const formattedValue = value ? dayjs(value).format("YYYY-MM-DD") : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label || "Select date"}
                value={formattedValue}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="YYYY-MM-DD"
            />
        </LocalizationProvider>
    );
}
