import { React, useState, useEffect } from 'react';
import GeneralTable from '../components/GeneralTable';
import { getMenuItems } from '../api/MenuItemsAPI';
import { CircularProgress, Box} from '@mui/material';

const tableHeaders = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      description: 'ID of the Menu Item'
    },
    {
      id: 'itemname',
      numeric: false,
      disablePadding: true,
      label: 'Item Name',
      description: 'Name of the Menu Item'
    },
    {
      id: 'itemdescription',    
      numeric: false,
      disablePadding: true,
      label: 'Item Description',
      description: 'Description of the Menu Item'
    },
    {
      id: 'itemlink',    
      numeric: false,
      disablePadding: true,
      label: 'Item Link',
      description: 'Link of the Menu Item'
    },
];

const createElementDialogData = {
  tabs: [
  {
      label: 'General Information',
      content: {
          elements: [
              { type: 'text', name: 'id', label: 'ID', fullWidth: true },
              { type: 'text', name: 'itemname', label: 'Menu Item Name', fullWidth: true },
              { type: 'text', name: 'itemdescription', label: 'Menu Item Description', fullWidth: true },
              { type: 'text', name: 'itemlink', label: 'Menu Item Link', fullWidth: true },
          ]
      }
  }
]};

const generateRightDrawer = {
  header: 'Menu Item',
  buttonText: 'Update',
  serviceType: 'clientMenuItemMaintenance',
  tabs: [
    {
      label: 'General Information',
      data: [
        { type: 'text', id: 'id', label: 'ID' },
        { type: 'text', id: 'itemname', label: 'Name' },
        { type: 'text', id: 'itemdescription', label: 'Description' },
        { type: 'text', id: 'itemlink', label: 'Link' },
      ]
    }
  ],
}

export default function ClientMenuItemMaintenance() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const generateToolbar = true;
    const serviceType = 'clientMenuItemMaintenance';
    const itemID = 'id';

    useEffect(() => {
        const fetchData = async () => {
          try {
            const menuitems = await getMenuItems();
            setData(menuitems.data);
          } catch (error) {
            console.error("Failed to fetch menuitems:", error);
          } finally {
            setLoading(false); 
          }
        };
    
        fetchData();
      }, []);

      if (loading) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        );
      }

    return (
        <GeneralTable 
          data={data} 
          itemID={itemID}
          tableHeaders={tableHeaders} 
          generateToolbar={generateToolbar} 
          createElementDialogData={createElementDialogData}
          generateRightDrawer={generateRightDrawer}
          serviceType={serviceType}
        />
      )
}