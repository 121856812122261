import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import DisplayGeneralData from "./DisplayGeneralData";
import CustomTabs from "../Tabs/index";
import DataColumnView from "./DataColumnView";
import UpdateElement from "../form/UpdateElement";
import { CircularProgress, Box } from "@mui/material";
import { getSecurityGroups } from "../../api/SecurityGroupsAPI";
import { getMenuItems } from "../../api/MenuItemsAPI";

export default function DataRightDrawer({ generateRightDrawer, isOpen, onClose, rowData, onSubmit, updatePayReq = false, tenant_security_group }) {
  const { header, serviceType, tabs, buttonText } = generateRightDrawer;
  const [loading, setLoading] = useState(true);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let options = {};
        if (serviceType === "userMaintenance") {
          const securityGroupsData = await getSecurityGroups();
          if (tenant_security_group == "Client"){
            options.securitygroups = [
              { value: 'Client', label: 'Client' },
            ]
          } else{
            options.securitygroups = securityGroupsData.data.map(group => ({
              value: group.id,
              label: group.id,
            }));
          }
        }
        else if (serviceType === 'securityGroup') {
          const menuItemsData = await getMenuItems();
          options.menuitems = menuItemsData.data.map((item) => ({
            value: item.id,
            label: item.itemname,
            description: item.itemdescription,
          }));
        }
        setFetchedOptions(options);
      } catch (error) {
        console.error("Failed to fetch security groups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const getTabComponent = (fieldData, tabLabel) => {
    if (serviceType == "PaymentRequest" && !updatePayReq) {
      return fieldData.map((field) => (
        <DataColumnView key={field.id} data={rowData} tabData={[field]} />
      ));
    } else {
      return (
        // clientMenuItemMaintenance, userMaintenance, and securityGroup
        <UpdateElement
          key={tabLabel}
          serviceType={serviceType}
          tabData={fieldData}
          data={rowData}
          onSubmit={onSubmit}
          buttonText={buttonText}
          fetchedOptions={fetchedOptions}
        />
      );
    }
  };

  const tabComponents = tabs.map((tab) => ({
    label: tab.label,
    component: (
      <Box sx={{ padding: 2 }}>{getTabComponent(tab.data, tab.label)}</Box>
    ),
  }));

  const list = (
    <Box
      sx={{
        width: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
      role="presentation"
    >
      {rowData && (
        <>
          {/* Title */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#101828",
              margin: 2,
              alignSelf: "flex-start",
            }}
          >
            {header}
          </Typography>

          <Box sx={{ width: "90%" }}>
            {/* General Information Section */}
            {generateRightDrawer.fields && (
              <DisplayGeneralData
                rowData={rowData}
                fields={generateRightDrawer.fields}
              />
            )}

            {/* Tab Panel Section */}
            <CustomTabs tabs={tabComponents} />
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      {list}
    </Drawer>
  );
}
