import React from 'react';
import { Button } from '@mui/material';

const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      sx={{
        width: 'calc(50% - 10px)',
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
