import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Header from "../components/Header";
import { Box } from "@mui/material";
import AppTabPanel from "../components/sub-components/AppTabPanel";

import ClientMaintenance from "../services/ClientMaintenance";
import ClientMenuItemMaintenance from "../services/ClientMenuItemMaintenance";
import { useAuth } from "../context/AuthContext";
import { getAuthCookies } from "./../context/CookieManager";

import PaymentRequest from "../services/PaymentRequest";
import PaymentProcessing from "../services/PaymentProcessing";
import SecurityGroups from "../services/SecurityGroups";
import Terminal from "../services/Terminal";
import UserMaintenance from "../services/UserMaintenance";
import ClientMaintenanceForm from "../components/form/ClientMaintenanceForm";
import { useState } from "react";
import { getSecurityGroupAPI } from "../api/SecurityGroupsAPI";
import { getMenuItemAPI } from "../api/MenuItemsAPI";

const Admin = () => {
  const [adminTabs, setAdminTabs] = useState([]);

  const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : <Navigate to="/" replace />;
  };

  const Menu = {
    terminal: <Terminal />,
    "payment-processing": <PaymentProcessing />,
    "deposit-request-report": <PaymentRequest />,
    "client-menu-item-maintenance": <ClientMenuItemMaintenance />,
    "client-maintenance": <ClientMaintenance />,
    "security-groups": <SecurityGroups />,
    "user-maintenance": <UserMaintenance />,
  };

  const getAllowedTabs = async () => {
    try {
      const currentSecurityGroup = getAuthCookies("securityGroup");
      const jsonResponse = await getSecurityGroupAPI(currentSecurityGroup);
      const MenuItems = jsonResponse.menuitems ?? "";
      const MenuItemsArray = MenuItems.split(",");

      const visibleTabs = [];

      const menuPromises = MenuItemsArray.map(async (element) => {
        const link = await getMenuItemAPI(element);
        return {
          label: link.itemname,
          path: "/" + link.itemlink,
          component: Menu[link.itemlink],
        };
      });
      const results = await Promise.all(menuPromises);
      visibleTabs.push(...results);
      setAdminTabs(visibleTabs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllowedTabs();
  }, []);

  return (
    <ProtectedRoute>
      {/* Protected Route */}
      <Header />
      <Box sx={{ width: "100%", pt: 8 }}>
        <Box
          sx={{
            width: { xs: "100%", sm: "90%" },
            mx: "auto",
            pt: 5,
          }}
        >
          <AppTabPanel tabs={adminTabs} prefix={"/admin"} />
          <Routes>
            {adminTabs.map((tab, index) => (
              <Route key={index} element={tab.component} path={tab.path} />
            ))}
            {adminTabs.length > 0 && (
              <Route
                path="*"
                element={<Navigate to={"/admin" + adminTabs[0]?.path} />}
              />
            )}
            <Route
              path="/client-maintenance/create"
              element={<ClientMaintenanceForm />}
            />
            <Route
              path="/client-maintenance/:id"
              element={<ClientMaintenanceForm />}
            />
          </Routes>
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default Admin;
