import { SecurityGroup as SGmodel } from "../models/SecurityGroup";
import { BASE_URL } from "../config";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getSecurityGroupAPI = async (id) => {
  try {
    const response = await axiosInstance.get(`security_groups/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getSecurityGroups = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/security_groups/`);
    const data = response.data.data.map((item) => new SGmodel(item));
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};
const createSecurityGroup = async (newGroup) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/security_groups/`,
      newGroup,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = new SGmodel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Security group created successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const updateSecurityGroup = async (updatedSecurityGroup) => {
  try {
    updatedSecurityGroup.id = updatedSecurityGroup.name;

    const response = await axiosInstance.put(
      `${BASE_URL}/security_groups/${updatedSecurityGroup.name}`,
      updatedSecurityGroup,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = new SGmodel(response.data.data);
    return new StandardResponse(
      data,
      response.data.status_code,
      "Security group updated successfully",
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const deleteSecurityGroup = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/security_groups/${id}`,
    );
    return new StandardResponse(
      null,
      response.data.status_code,
      "Security group deleted successfully",
    );
  } catch (error) {}
};

export {
  getSecurityGroupAPI,
  getSecurityGroups,
  createSecurityGroup,
  updateSecurityGroup,
  deleteSecurityGroup,
};
