import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export default function DisplayGeneralData({ rowData, fields }) {
  return (
    <Paper elevation={0} sx={{ border: "1px solid #D0D5DD" }}>
      <Box sx={{ padding: "12px" }} display={"flex"}>
        <PersonOutlineIcon />
        <Box
          sx={{
            paddingLeft: "12px",
          }}
        >
          {rowData && (
            <Box>
              {fields.map((field) => (
                <Typography
                  key={field.key}
                  variant="body2"
                  sx={{
                    fontFamily: "Hanken Grotesk, sans-serif",
                    color: "#101828",
                    marginBottom: "8px",
                  }}
                >
                  {`${rowData[field.key]}`}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
