import React from 'react'
import Box from '@mui/material/Box';

function Switch({
    values, value,
    switchHandler
}) {

    if (values.length !== 2) {
        return <Box>Invalid values</Box>;
    }
    return (
        <Box
            sx={{
                p: 0.5,
                fontFamily: 'Hanken Grotesk',
                borderRadius: '100px',
            }}
            className='switch-gradient'>
            <Box
                display={"flex"}
                flexDirection={'row'}
                sx={{
                    borderRadius: '100px',
                    background: 'white',
                    p: 0.5
                }}>
                <Box sx={{
                    px: 2,
                    py: 1,
                    borderRadius: '100px',
                    background: value === values[0] ? 'black' : 'white',
                    color: value === values[0] ? 'white' : 'black',
                    cursor: 'pointer',
                }}
                onClick={() => switchHandler(values[0])}
                >
                    {values[0].charAt(0).toUpperCase() + values[0].slice(1)}
                </Box>
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: '100px',
                        background: value === values[1] ? 'black' : 'white',
                        color: value === values[1] ? 'white' : 'black',
                        cursor: 'pointer',
                    }}
                    onClick={() => switchHandler(values[1])}
                >
                    {values[1].charAt(0).toUpperCase() + values[1].slice(1)}
                </Box>
            </Box>
        </Box>
    )
}

export default Switch