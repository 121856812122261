import React, { createContext, useContext, useState } from 'react';
import { setAuthCookies, getAuthCookies, clearAuthCookies } from './CookieManager';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: getAuthCookies().accessToken ? true : false,
        token: getAuthCookies().accessToken || null,
    });

    const login = (loginData, username) => {
        setAuthCookies(loginData, username);
        setAuthState({
            isAuthenticated: true,
            token: loginData.accessToken,
        });
    };

    const logout = () => {
        clearAuthCookies();
        setAuthState({
            isAuthenticated: false,
            token: null,
        });
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
