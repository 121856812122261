export class DockMasterCustomer {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.name = data.name || data.Name || "";
    this.firstName = data.firstName || data.FirstName || "";
    this.lastName = data.lastName || data.LastName || "";
    this.address1 = data.address1 || data.Address1 || "";
    this.address2 = data.address2 || data.Address2 || "";
    this.address3 = data.address3 || data.Address3 || "";
    this.city = data.city || data.City || "";
    this.state = data.state || data.State || "";
    this.zip = data.zip || data.Zip || "";
    this.country = data.country || data.Country || "";
    this.phone = data.phone || data.Phone || "";
    this.altFirstName = data.altFirstName || data.AltFirstName || "";
    this.altLastName = data.altLastName || data.AltLastName || "";
    this.altAddress1 = data.altAddress1 || data.AltAddress1 || "";
    this.altAddress2 = data.altAddress2 || data.AltAddress2 || "";
    this.altAddress3 = data.altAddress3 || data.AltAddress3 || "";
    this.altCity = data.altCity || data.AltCity || "";
    this.altState = data.altState || data.AltState || "";
    this.altZip = data.altZip || data.AltZip || "";
    this.altCountry = data.altCountry || data.AltCountry || "";
    this.altPhone = data.altPhone || data.AltPhone || "";
    this.useAltAddress = data.useAltAddress || data.UseAltAddress || false;
    this.creditLimit = data.creditLimit || data.CreditLimit || 0;
    this.workPhone = data.workPhone || data.WorkPhone || "";
    this.cellPhone = data.cellPhone || data.CellPhone || "";
    this.email = data.email || data.Email || "";
    this.balance = data.balance || data.Balance || 0;
    this.priceColumn = data.priceColumn || data.PriceColumn || "1";
    this.sendMassEmail = data.sendMassEmail || data.SendMassEmail || false;
    this.discount = data.discount || data.Discount || 0;
    this.emergencyContact =
      data.emergencyContact || data.EmergencyContact || "";
    this.emergencyPhone = data.emergencyPhone || data.EmergencyPhone || "";
    this.lastModified = data.lastModified || data.LastModified || "";
    this.inactive = data.inactive || data.Inactive || false;
    this.allowTransactions =
      data.allowTransactions || data.AllowTransactions || false;
    this.categoryCodes = data.categoryCodes
      ? data.categoryCodes.map((cc) => new CategoryCode(cc))
      : data.CategoryCodes
      ? data.CategoryCodes.map((cc) => new CategoryCode(cc))
      : [];
    this.boats = data.boats
      ? data.boats.map((boat) => new Boat(boat))
      : data.Boats
      ? data.Boats.map((boat) => new Boat(boat))
      : [];
    this.invoices = data.invoices
      ? data.invoices.map((invoice) => new Invoice(invoice))
      : data.Invoices
      ? data.Invoices.map((invoice) => new Invoice(invoice))
      : [];
    this.taxID = data.taxID || data.TaxID || "";
    this.taxIDState = data.taxIDState || data.TaxIDState || "";
    this.poRequired = data.poRequired || data.PoRequired || false;
    this.comments = data.comments || data.Comments || "";
    this.paymentTermsCode =
      data.paymentTermsCode || data.PaymentTermsCode || "";
    this.paymentTermsCodeDescription =
      data.paymentTermsCodeDescription ||
      data.PaymentTermsCodeDescription ||
      "";
    this.companyName = data.companyName || "";
    this.prospectId = data.prospectId || "";
    this.waitListEntries = data.waitListEntries || "";
  }
}

export class CategoryCode {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.desc = data.desc || data.Desc || "";
  }
}

export class Boat {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.ownerId = data.ownerId || data.OwnerId || "";
    this.name = data.name || data.Name || "";
    this.registration = data.registration || data.Registration || "";
    this.year = data.year || data.Year || "";
    this.make = data.make || data.Make || "";
    this.model = data.model || data.Model || "";
    this.hin = data.hin || data.Hin || "";
    this.loa = data.loa || data.Loa || "";
    this.lwl = data.lwl || data.Lwl || "";
    this.draft = data.draft || data.Draft || "";
    this.beam = data.beam || data.Beam || "";
    this.height = data.height || data.Height || "";
    this.lastModified = data.lastModified || data.LastModified || "";
    this.doNotLaunch = data.doNotLaunch || data.DoNotLaunch || false;
    this.color = data.color || data.Color || "";
    this.trailerMake = data.trailerMake || data.TrailerMake || "";
    this.trailerModel = data.trailerModel || data.TrailerModel || "";
    this.trailerSerial = data.trailerSerial || data.TrailerSerial || "";
    this.trailerRegistration =
      data.trailerRegistration || data.TrailerRegistration || "";
    this.trailerLocation = data.trailerLocation || data.TrailerLocation || "";
    this.summerSlip = data.summerSlip || data.SummerSlip || "";
    this.winterSlip = data.winterSlip || data.WinterSlip || "";
    this.insuranceCompany =
      data.insuranceCompany || data.InsuranceCompany || "";
    this.comments = data.comments || data.Comments || "";
    this.motors = data.motors
      ? data.motors.map((motor) => new Motor(motor))
      : data.Motors
      ? data.Motors.map((motor) => new Motor(motor))
      : [];
    this.billingCodes = data.billingCodes
      ? data.billingCodes.map((code) => new BillingCode(code))
      : data.BillingCodes
      ? data.BillingCodes.map((code) => new BillingCode(code))
      : [];
    this.boatDescriptionCodes = data.boatDescriptionCodes
      ? data.boatDescriptionCodes.map((code) => new BillingCode(code))
      : data.BoatDescriptionCodes
      ? data.BoatDescriptionCodes.map((code) => new BillingCode(code))
      : [];
    this.customInformation =
      data.customInformation || data.CustomInformation || [];
  }
}

export class Motor {
  constructor(data) {
    this.number = data.number || data.Number || "";
    this.year = data.year || data.Year || "";
    this.make = data.make || data.Make || "";
    this.model = data.model || data.Model || "";
    this.serial = data.serial || data.Serial || "";
  }
}

export class BillingCode {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.description = data.description || data.Description || "";
    this.department = data.department || data.Department || "";
    this.departmentDesc = data.departmentDesc || data.DepartmentDesc || "";
    this.cycle = data.cycle || data.Cycle || "";
    this.perFoot = data.perFoot || data.PerFoot || 0;
    this.proRated = data.proRated || data.ProRated || "";
    this.slipBoatOrLongest =
      data.slipBoatOrLongest || data.SlipBoatOrLongest || "";
    this.lengthAreaOrCubicFeet =
      data.lengthAreaOrCubicFeet || data.LengthAreaOrCubicFeet || "";
    this.loaLwlOrSpar = data.loaLwlOrSpar || data.LoaLwlOrSpar || "";
    this.rates = data.rates
      ? data.rates.map((rate) => ({
          rate: rate.rate || rate.Rate || 0,
          startDate: rate.startDate || rate.StartDate || "",
          endDate: rate.endDate || rate.EndDate || "",
        }))
      : [];
  }
}

export class Invoice {
  constructor(data) {
    this.id = data.id || data.Id || "";
    this.customerId = data.customerId || data.CustomerId || "";
    this.amount = data.amount || data.Amount || 0;
    this.invoiceAmount = data.invoiceAmount || data.InvoiceAmount || 0;
    this.invoiceBalance = data.invoiceBalance || data.InvoiceBalance || 0;
    this.dueDate = data.dueDate || data.DueDate || "";
    this.description = data.description || data.Description || "";
    this.locationCode = data.locationCode || data.LocationCode || "";
    this.unAppliedPayment = data.unAppliedPayment || data.UnAppliedPayment || 0;
    this.installments = data.installments
      ? data.installments.map((installment) => ({
          id: installment.id || installment.ID || "",
          amount: installment.amount || installment.Amount || 0,
          dueDate: installment.dueDate || installment.DueDate || "",
        }))
      : [];
  }
}
