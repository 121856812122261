import { React, useState, useEffect, useCallback, useMemo } from "react";
import {
  getPayments,
  getPaymentById,
  updatePaymentAPI,
} from "../api/PaymentAPI";
import _ from "lodash";
import { submitBatchAPI } from "../api/DockMasterAPI";
import {
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { getUser } from "../api/UsersAPI";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import JSONPretty from "react-json-pretty";
import { getAuthCookies } from "../context/CookieManager";
import { getTenantAPI } from "../api/TenantAPI";
import RowDetails from "./../components/Tabs/RowDetails";
import { Select, MenuItem } from "@mui/material";
import { TableSortLabel } from "@mui/material";

const paymentStates = [
  { value: "PAID", label: "Paid" },
  { value: "VOIDED", label: "Voided" },
  { value: "REFUNDED", label: "Refunded" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "FAILURE", label: "Failure" },
  { value: "PENDING", label: "Pending" },
];

const paymentStatus = [
  { value: "UNPROCESSED", label: "Unprocessed" },
  { value: "PROCESSED", label: "Processed" },
];

const tableHeaders = [
  { id: "id", label: "ID", type: "text", width: "200px" },
  { id: "status", label: "Status", type: "select", width: "150px" },
  {
    id: "payment_state",
    label: "Payment State",
    type: "select",
    width: "150px",
  },
  { id: "payment_date", label: "Payment Date", type: "date", width: "150px" },
  { id: "customer_id", label: "Cust ID", type: "number", width: "80px" },
  { id: "firstname", label: "First Name", type: "text", width: "150px" },
  { id: "lastname", label: "Last Name", type: "text", width: "150px" },
  {
    id: "transaction_type",
    label: "Tran.Type",
    type: "text",
    width: "80px",
  },
  {
    id: "payment_method",
    label: "Method",
    type: "text",
    width: "80px",
  },
  { id: "last_four", label: "Last Four", type: "text", width: "100px" },
  { id: "location", label: "Location", type: "text", width: "200px" },
  { id: "batch_id", label: "Batch ID", type: "text", width: "200px" },
  {
    id: "reference_number",
    label: "Reference Number",
    type: "text",
    width: "150px",
  },
  { id: "feeinvoiceid", label: "Reference", type: "text", width: "200px" },
  { id: "amount", label: "Amount", type: "number", width: "150px" },
  { id: "totalamount", label: "Total Amount", type: "number", width: "150px" },
  { id: "primary_email", label: "Primary Email", type: "text", width: "200px" },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    filterValues,
    handleFilterChange,
    submitFilter,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* Adding an empty header cell at the start for alignment */}
        <TableCell style={{ width: "48px" }} />{" "}
        {/* Adjust width as needed to match the TableRow icons */}
        {tableHeaders.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ flexGrow: 1 }}
              >
                {headCell.label}
              </TableSortLabel>
              {headCell.type === "select" &&
                headCell.id === "payment_state" && (
                  <Select
                    size="small"
                    variant="outlined"
                    multiple
                    name={headCell.id}
                    value={
                      filterValues[headCell.id] ?? [
                        "PAID",
                        "REFUNDED",
                        "VOIDED",
                      ]
                    }
                    onChange={handleFilterChange}
                    onKeyDown={(event) => submitFilter(event, headCell.id)}
                    fullWidth
                    sx={{ mt: 1, width: headCell.width }}
                  >
                    {paymentStates.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              {headCell.type === "select" &&
                headCell.id !== "payment_state" && (
                  <Select
                    size="small"
                    variant="outlined"
                    name={headCell.id}
                    value={filterValues[headCell.id] || ""}
                    onChange={handleFilterChange}
                    onKeyDown={(event) => submitFilter(event, headCell.id)}
                    fullWidth
                    sx={{ mt: 1, width: headCell.width }}
                  >
                    {paymentStatus.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              {headCell.type !== "select" && (
                <TextField
                  size="small"
                  variant="outlined"
                  type={headCell.type}
                  name={headCell.id}
                  value={filterValues[headCell.id] || ""}
                  onChange={handleFilterChange}
                  onKeyDown={(event) => submitFilter(event, headCell.id)}
                  fullWidth
                  sx={{ mt: 1, width: headCell.width }}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const displayPaymentAmounts = (row) => {
  let result = "";

  if (row.batch_payload_req === "" || row.batch_payload_req == "[]")
    return parseFloat(row.amount).toFixed(2);

  let jsonObject;
  try {
    jsonObject = JSON.parse(row.batch_payload_req);
  } catch (error) {
    // console.error("Failed to parse batch_payload_req:", error);
    return parseFloat(row.amount).toFixed(2);
  }

  if (!jsonObject || !jsonObject[0] || !jsonObject[0].invPayments) {
    return parseFloat(row.amount).toFixed(2);
  }

  result = jsonObject[0].invPayments
    ?.map(
      (item) =>
        `${!item.customerId ? "(conv. fee)" : item.invoiceId} : ${parseFloat(
          item.paymentAmt,
        ).toFixed(2)}`,
    )
    .join("\n");
  result +=
    "\n (surcharge) : " + parseFloat(jsonObject[0].ccSurcharge).toFixed(2);
  //result += '\n (tax) : ' + parseFloat(jsonObject[0].ccSurchargeTax).toFixed(2)

  return result;
};

export default function PaymentProcessing() {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filtering, setFiltering] = useState({
    from_date: "",
    to_date: "",
  });
  const [loading, setLoading] = useState(true);
  const [filterValues, setFilterValues] = useState({
    payment_state: ["REFUNDED", "VOIDED", "PAID"],
  });
  const [query, setQuery] = useState({
    skip: 0,
    limit: 10,
    filters: filterValues,
    sort: "created_at_desc",
  });
 
  const [expandedRow, setExpandedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [jsonContent, setJsonContent] = useState("");
  const [canVoidRefund, setCanVoidRefund] = useState(
    getAuthCookies("voidRefundPermission") ?? false,
  );
  const [rowData, setRowData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [clientID, setClientID] = useState("");

  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    setFiltering((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchClientID = async () => {
      const user_id = getAuthCookies("tenantId");
      const tenant_info = await getTenantAPI(user_id);
      setClientID(user_id);
    };

    fetchClientID();
  }, []);

  useEffect(() => {
    if (clientID) {
      setLoading(true);
      const fetchPaymentData = async () => {
        try {
          const paymentResponse = await getPayments({ ...query, clientID });
          setData(paymentResponse.data);
          setTotalRows(paymentResponse.total_count);
        } catch (error) {
          console.error("Failed to fetch payments:", error);
        }
      };

      const fetchData = async () => {
        await fetchPaymentData();
        setLoading(false);
      };

      fetchData();
    }
  }, [query, clientID]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setQuery((prev) => ({
      ...prev,
      sort: property + "_" + (isAsc ? "asc" : "desc"),
    }));
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
    setRowData(data.find((row) => row.id === rowId));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    console.log("name", name, "value", value);

    if (name === "last_four") {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else if (name === "location") {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else if (name === "reference_number") {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else if (name === "batch_id") {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
    } else if (name === 'payment_state') {
      setFilterValues((prev) => ({
        ...prev,
        [name]: value ?? [],
      }));
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [name]: isNaN(value) ? value : parseFloat(value),
      }));
    }
  };

  const handleApplyFilters = () => {
    let updatedFilters = { ...filterValues };
    let params = {};

    if (filtering.from_date) {
      params["from_payment_date"] = filtering.from_date;
    }
    if (filtering.to_date) {
      params["to_payment_date"] = filtering.to_date;
    }

    if (updatedFilters.reference_number) {
      updatedFilters.referenceNumber = updatedFilters.reference_number;
      delete updatedFilters.reference_number;
    }

    if (updatedFilters.primary_email) {
      updatedFilters.primaryemail = updatedFilters.primary_email;
      delete updatedFilters.primary_email;
    }

    setQuery((prev) => ({
      ...prev,
      filters: updatedFilters,
      skip: 0,
      params: params,
    }));
  };

  const handleResetFilters = () => {
    setFilterValues({});
    setFiltering({
      from_date: "",
      to_date: "",
    });
    setQuery((prev) => ({
      ...prev,
      filters: {},
      skip: 0,
      sort: "created_at_desc",
      params: {},
    }));
  };

  const handleExportCSV = () => {
    const csv = convertToCSV(data);
    downloadCSV(csv, new Date().toISOString() + "-payment-data.csv");
  };

  const convertToCSV = (array) => {
    const csv = [
      tableHeaders.map((header) => header.label).join(","),
      ...array.map((row) =>
        tableHeaders.map((header) => row[header.id]).join(","),
      ), // data rows
    ].join("\n");
    return csv;
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const submitFilter = (event, fieldName) => {
    if (event.key === "Enter") {
      handleApplyFilters();
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleOpen = (data) => {
    setJsonContent(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSaveComment = async () => {
    try {
      await updatePaymentAPI(rowData.id, {
        comment: rowData.comment,
      });
      alert("Comment updated!");
    } catch (error) {}
  };
  //TODO: Fix the repost , updatepaymentwithbatchId to handle multiple batchIds and to also handle missing invoiceIds when it's a deposit payment
  const handleRepost = async (location, clientId, payload, paymentId) => {
    try {
      const jsonResponse = await submitBatchAPI(
        location,
        clientId,
        JSON.parse(payload),
      );
      if (jsonResponse?.status === 400) {
        alert("Repost Failed!");
      } else {
        alert("Repost Successful!");
        const batchId = jsonResponse.batchId; // Extract the batchId from the response
        await updatePaymentWithBatchId(paymentId, batchId);
      }
    } catch (error) {
      console.error("Error reposting batch:", error);
      alert("Repost failed due to an unexpected error.");
    }
  };

  const updatePaymentWithBatchId = async (paymentId, batchId) => {
    try {
      // Only send the fields that need updating
      const updatedPaymentData = {
        batch_id: batchId,
      };

      // Call the updatePaymentAPI with the updated data
      await updatePaymentAPI(paymentId, updatedPaymentData);
      alert("Payment updated successfully with new Batch ID!");
    } catch (error) {
      console.error("Error updating payment with new Batch ID:", error);
      alert("Failed to update payment with new Batch ID.");
    }
  };

  const handleRowRepost = (row) => {
    handleRepost(row.location, row.client_id, row.batch_payload_req, row.id);
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              margin: "16px 0",
            }}
          >
            <div>
              <TextField
                size="small"
                variant="outlined"
                type={"date"}
                name={"from_date"}
                value={filtering.from_date}
                onChange={handleChangeFilter}
                fullWidth
                sx={{ mt: 1, mr: 1, width: "200px" }}
              />
              <TextField
                size="small"
                variant="outlined"
                type={"date"}
                name={"to_date"}
                value={filtering.to_date}
                onChange={handleChangeFilter}
                fullWidth
                sx={{ mt: 1, width: "200px" }}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                sx={{ mr: 1 }}
              >
                Apply Filters
              </Button>
              <Button
                variant="outlined"
                onClick={handleResetFilters}
                sx={{ mr: 1 }}
              >
                Reset Filters
              </Button>
              <Button variant="outlined" onClick={handleExportCSV}>
                Export CSV
              </Button>
            </div>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ height: "100%", mt: 2 }}>
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  filterValues={filterValues}
                  handleFilterChange={handleFilterChange}
                  submitFilter={submitFilter}
                />
                <TableBody>
                  {data.map((row) => (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        onClick={() => toggleRowExpansion(row.id)}
                      >
                        <TableCell>
                          {expandedRow === row.id ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </TableCell>
                        {tableHeaders.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.numeric ? "right" : "left"}
                            wrap={
                              column.id === "amount" ? "break-word" : "nowrap"
                            }
                          >
                            {column.id === "batch_id" &&
                            (row[column.id] === null ||
                              row[column.id] === "") ? (
                              <Box sx={{ color: "red" }}>
                                Manual Processing Needed
                                {/*<Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleRepost(
                                      row["location"],
                                      row["client_id"],
                                      row["batch_payload_req"],
                                      row.id
                                    )
                                  }
                                  size="small"
                                  sx={{ ml: 2 }}
                                >
                                  Repost
                                </Button>*/}
                              </Box>
                            ) : column.id === "feeinvoiceid" ? (
                              row[column.id].length > 0 ? (
                                row[column.id].join(", ")
                              ) : (
                                ""
                              )
                            ) : column.id === "totalamount" ? (
                              row[column.id] ? (
                                parseFloat(row[column.id]).toFixed(2)
                              ) : (
                                ""
                              )
                            ) : column.id === "amount" ? (
                              row[column.id] ? (
                                <div
                                  style={{
                                    whiteSpace: "pre-line",
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {displayPaymentAmounts(row)}
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              row[column.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      {expandedRow === row.id && (
                        <TableRow>
                          <TableCell colSpan={16}>
                            <RowDetails
                              row={row}
                              expandedRow={expandedRow}
                              tabValue={tabValue}
                              handleChange={handleChange}
                              handleOpen={handleOpen}
                              canVoidRefund={canVoidRefund}
                              rowData={rowData}
                              TabPanel={TabPanel}
                              handleSaveComment={handleSaveComment}
                              setRowData={setRowData}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              component="div"
              count={totalRows}
              rowsPerPage={query.limit}
              page={query.skip / query.limit}
              onPageChange={(_, newPage) =>
                setQuery((prev) => ({ ...prev, skip: newPage * query.limit }))
              }
              onRowsPerPageChange={(event) =>
                setQuery((prev) => ({
                  ...prev,
                  limit: parseInt(event.target.value, 10),
                  skip: 0,
                }))
              }
            />
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="json-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="json-dialog-title">JSON Data</DialogTitle>
        <DialogContent>
          <JSONPretty id="json-pretty" data={jsonContent}></JSONPretty>
        </DialogContent>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </Dialog>
    </Box>
  );
}
