import { Payment as PaymentModel } from "../models/Payment";
import { StandardResponse } from "../assets/StandardResponse";
import axiosInstance from "../helpers/axiosInstance";

const getPayments = async ({
  skip = 0,
  limit = 10,
  filters = {},
  sort = "created_at_desc",
  clientID, // Add clientID parameter
  params,
} = {}) => {
  const paramsData = new URLSearchParams({
    skip,
    limit,
    filters: JSON.stringify(filters),
    sort,
    client_id: clientID, // Add clientID to query parameters
    ...params,
  });

  try {
    const response = await axiosInstance.get(
      `payments/?${paramsData.toString()}`,
    );
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      const data = jsonResponse.data.map((item) => new PaymentModel(item));
      return new StandardResponse(
        data,
        jsonResponse.status_code,
        jsonResponse.message,
        jsonResponse.total_count,
      );
    } else {
      const errorMessage =
        jsonResponse.message || "An error occurred while fetching payments.";
      return new StandardResponse(
        [],
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const createPaymentAPI = async (newPayment) => {
  try {
    const response = await axiosInstance.post(`payments/`, newPayment);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};
const updatePaymentAPI = async (id, payment) => {
  try {
    const response = await axiosInstance.put(`payments/${id}`, payment);
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getPaymentById = async (id) => {
  try {
    const response = await axiosInstance.get(`payments/${id}`);
    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      return new PaymentModel(jsonResponse.data);
    } else {
      const errorMessage =
        jsonResponse.message || `An error occurred while fetching payment with ID ${id}.`;
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    throw new Error(
      error?.message || `There was a problem with the axios operation while fetching payment with ID ${id}.`,
    );
  }
};

export { createPaymentAPI, getPayments, updatePaymentAPI, getPaymentById };
