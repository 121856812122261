export class Credential {
  constructor(data = {}) {
    this.Id = data.Id || "";
    this.ClientId = data.ClientId || null; // Assuming ClientId is an integer
    this.DbType = data.DbType || "";
    this.SqlHost = data.SqlHost || "";
    this.SqlDb = data.SqlDb || "";
    this.SqlUser = data.SqlUser || "";
    this.SqlPassword = data.SqlPassword || "";
    this.DmApiUrl = data.DmApiUrl || "";
    this.DmApiClerk = data.DmApiClerk || ""; // Assuming DmApiClerkUsername is called DmApiClerk in JS
    this.DmApiClerkPassword = data.DmApiClerkPassword || "";
    this.DmAuthCode = data.DmAuthToken || ""; // Changed to DmAuthToken for consistency
    this.AnetTranId = data.AnetTranId || "";
    this.AnetTranKey = data.AnetTranKey || "";
    this.AnetClientKey = data.AnetClientKey || "";
    this.TestAnetTranId = data.TestAnetTranId || "";
    this.TestAnetTranKey = data.TestAnetTranKey || "";
    this.TestAnetClientKey = data.TestAnetClientKey || "";
    this.WpAccountId = data.WpAccountId || "";
    this.WpToken = data.WpToken || "";
    this.WpAcceptorId = data.WpAcceptorId || "";
    this.WpApplicationId = data.WpApplicationId || "";
    this.TestWpAccountId = data.TestWpAccountId || "";
    this.TestWpToken = data.TestWpToken || "";
    this.TestWpAcceptorId = data.TestWpAcceptorId || "";
    this.TestWpApplicationId = data.TestWpApplicationId || "";
    this.VpMerchantId = data.VpMerchantId || ""; // Added fields from Python model
    this.VpApiKey = data.VpApiKey || "";
    this.VpClientKey = data.VpClientKey || "";
    this.VpSubMerchantId = data.VpSubMerchantId || "";
    this.VpStoreId = data.VpStoreId || "";
    this.TestVpMerchantId = data.TestVpMerchantId || "";
    this.TestVpApiKey = data.TestVpApiKey || "";
    this.TestVpClientKey = data.TestVpClientKey || "";
    this.TestVpSubMerchantId = data.TestVpSubMerchantId || "";
    this.TestVpStoreId = data.TestVpStoreId || "";
    this.isOldApi = data.isOldApi;
    this.DMAPIAuthUrl = data.DMAPIAuthUrl || ""; // Added field
    this.SystemIdList = data.SystemIdList || [];
    this.DmApiClerkUsername = data.DmApiClerkUsername || "";
    this.DefaultSystemId = data.DefaultSystemId || "";
  }
}
