import { StandardResponse } from "../assets/StandardResponse";
import { Customer as CustomerModel } from "../models/Customer";
import { Prospect as ProspectModel } from "../models/Prospect";
import { DockMasterCustomer as DockMasterCustomerModel } from "../models/DockMasterCustomer";
import { CustomerContract } from "../models/CustomerContract";
import { CustomerReservation } from "../models/CustomerReservation";
import axiosInstance from "../helpers/axiosInstance";
import { SpecialOrder } from "../models/SpecialOrder";
import { Clerks } from "../models/Clerks";
import { CustomerInvoices } from "../models/CustomerInvoices";
import { WorkOrder } from "../models/WorkOrder";

const getCustomers = async (user_id, search_term) => {
  try {
    const encodedSearchTerm = encodeURIComponent(search_term);
    const url = `dockmaster/search-customers/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    const data = response.data.data.map((item) => new CustomerModel(item));
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getProspects = async (user_id, search_term) => {
  try {
    const encodedSearchTerm = encodeURIComponent(search_term);
    const url = `dockmaster/search-prospects/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    const data = response.data.data.map((item) => new ProspectModel(item));
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getLocationsAPI = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      `dockmaster/list-locations?user_id=${customerId}`,
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerInformation = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);
    const response = await axiosInstance.get(
      `dockmaster/retrieve-customer-information/${encodedSearchTerm}?user_id=${user_id}`,
    );
    const data = [new DockMasterCustomerModel(response.data.data)];
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getProspectInformation = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);
    const url = `dockmaster/retrieve-prospect-information/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    const data = [new ProspectModel(response.data.data)];
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerContracts = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);
    const url = `dockmaster/retrieve-customer-contracts/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    let data = [];
    if (response.data.data && response.data.data.length > 0) {
      data = response.data.data.map(
        (contract) => new CustomerContract(contract),
      );
    }
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerQuotes = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);
    const url = `dockmaster/retrieve-customer-quotes/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    let data = [];
    if (response.data.data && response.data.data.length > 0) {
      data = response.data.data.map((quote) => new CustomerContract(quote)); // Change the model to CustomerQuotes if different from CustomerContract
    }
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};
const getCustomerReservations = async (user_id, reservation_id) => {
  try {
    const encodedSearchTerm = encodeURIComponent(reservation_id);
    const url = `dockmaster/retrieve-reservations/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    let data = [];
    if (response.data.data) {
      const element = new CustomerReservation(response.data.data);
      data.push(element);
    }
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerInvoicesAPI = async (customerId, tenantId) => {
  try {
    const response = await axiosInstance.get(
      `dockmaster/load-customer-invoices?customer_id=${customerId}&tenant_id=${tenantId}`,
    );
    return new CustomerInvoices(response.data.data);
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getPayTypes = async (user_id) => {
  try {
    const response = await axiosInstance.get(
      `dockmaster/retrieve-pay-types?user_id=${user_id}`,
    );
    const jsonResponse = response.data;

    if (
      response.status === 200 &&
      jsonResponse.success &&
      jsonResponse.status_code === 200
    ) {
      return new StandardResponse(
        jsonResponse.data,
        jsonResponse.status_code,
        jsonResponse.message,
      );
    } else {
      const errorMessage =
        jsonResponse.message ||
        "An error occurred while fetching the pay types.";
      return new StandardResponse(
        [],
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const getSystemIdTokenAPI = async (tenantId, userName, password, authUrl) => {
  try {
    const response = await axiosInstance.post(
      `dockmaster/get-token-systemid`,
      {},
      {
        params: {
          tenant_id: tenantId,
          username: userName,
          password: password,
          auth_url: authUrl,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerSpecialOrders = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);

    const response = await axiosInstance.get(
      `dockmaster/customer-special-orders/${encodedSearchTerm}?user_id=${user_id}`,
    );
    const jsonResponse = response.data;
    let data = [];

    if (
      response.status === 200 &&
      jsonResponse.status_code === 200 &&
      jsonResponse.data &&
      jsonResponse.data.length > 0
    ) {
      data = jsonResponse.data.map(
        (specialOrder) => new SpecialOrder(specialOrder),
      );
    }

    const message =
      jsonResponse.message ||
      (data.length
        ? "Special orders retrieved successfully."
        : "No special orders found.");

    return new StandardResponse(data, jsonResponse.status_code || 200, message);
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const getClerks = async (user_id) => {
  try {
    const response = await axiosInstance.get(
      `dockmaster/list-clerks?user_id=${user_id}`,
    );
    const jsonResponse = response.data;

    let data = [];
    if (
      response.status === 200 &&
      jsonResponse.status_code === 200 &&
      jsonResponse.data &&
      jsonResponse.data.length > 0
    ) {
      data = jsonResponse.data.map((clerk) => new Clerks(clerk));
    }
    const message =
      jsonResponse.message ||
      (data.length ? "Clerks retrieved successfully." : "No Clerks found.");
    return new StandardResponse(data, jsonResponse.status_code || 200, message);
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};

const getAuthTokenAPI = async (tenantId) => {
  try {
    const response = await axiosInstance.get(`dockmaster/get-auth-token`, {
      params: { tenant_id: tenantId },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const setCurrentSystemIdAPI = async (tenantId, systemId) => {
  try {
    const response = await axiosInstance.post(
      `dockmaster/set-system-id`,
      {},
      {
        params: { tenant_id: tenantId, system_id: systemId },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const reversePayment = async (
  user_id,
  customerId,
  batchId,
  referenceId,
  statementDesc,
) => {
  // If batchId is not provided, return a success response without making the API call
  if (!batchId) {
    return new StandardResponse(
      [],
      200,
      "Batch ID is not provided, no action taken with DockMasterAPI."
    );
  }

  try {
    const params = new URLSearchParams({
      user_id,
      customerId,
      batchId,
      referenceId,
      statementDesc,
    });

    const response = await axiosInstance.post(
      `dockmaster/reverse-payment?${params.toString()}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const jsonResponse = response.data;

    if (response.status === 200 && jsonResponse.status_code === 200) {
      return new StandardResponse(
        jsonResponse.data,
        jsonResponse.status_code,
        jsonResponse.message,
      );
    } else {
      const errorMessage =
        jsonResponse.message ||
        "An error occurred while reversing the payment.";
      return new StandardResponse(
        [],
        jsonResponse.status_code || 400,
        errorMessage,
      );
    }
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    return new StandardResponse(
      [],
      500,
      "There was a problem with the axios operation.",
    );
  }
};


const submitBatchAPI = async (locationCode, userId, data, postBatch) => {
  try {
    const response = await axiosInstance.post(`dockmaster/submit-batch`, data, {
      params: {
        location_code: locationCode,
        user_id: userId,
        post_batch: postBatch ?? true,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const createMiscArChargeAPI = async (miscChargeReq) => {
  try {
    const response = await axiosInstance.post(
      `dockmaster/create-misc-ar-charge`,
      {},
      {
        params: {
          ...miscChargeReq,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getTaxesAPI = async (
  tenantId,
  referenceNum,
  customerId,
  locationCode,
  saleAmount,
) => {
  try {
    const response = await axiosInstance.post(
      `dockmaster/calculate-tax`,
      {},
      {
        params: {
          tenant_id: tenantId,
          reference_number: referenceNum,
          customer_id: customerId,
          location_code: locationCode,
          sale_amount: saleAmount,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};

const getCustomerOpenWorkOrders = async (user_id, custid) => {
  try {
    const encodedSearchTerm = encodeURIComponent(custid);
    const url = `dockmaster/list-for-customer-related-to-service-work-orders/${encodedSearchTerm}?user_id=${user_id}`;
    const response = await axiosInstance.get(url);
    const data = response.data.data.map((item) => new WorkOrder(item));
    return new StandardResponse(
      data,
      response.data.status_code,
      response.data.message,
    );
  } catch (error) {
    return new StandardResponse(
      {},
      500,
      error.response?.data?.message ||
        "There was a problem with the Axios operation.",
    );
  }
};
const getNextReferenceNumberAPI = async (tenantId, customerId) => {
  try {
    const response = await axiosInstance.get(
      `dockmaster/get-next-reference-number/${customerId}`,
      {
        params: {
          tenant_id: tenantId,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      error?.message || "There was a problem with the Axios operation.",
    );
  }
};


export {
  getCustomers,
  getProspects,
  getLocationsAPI,
  getCustomerInformation,
  getProspectInformation,
  getCustomerContracts,
  getCustomerQuotes,
  getCustomerReservations,
  getCustomerInvoicesAPI,
  getPayTypes,
  getCustomerSpecialOrders,
  getClerks,
  getSystemIdTokenAPI,
  getAuthTokenAPI,
  setCurrentSystemIdAPI,
  reversePayment,
  submitBatchAPI,
  createMiscArChargeAPI,
  getTaxesAPI,
  getCustomerOpenWorkOrders,
  getNextReferenceNumberAPI
};

