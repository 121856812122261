import * as React from "react";
import { Popper } from "@mui/base/Popper";
import { styled, css } from "@mui/system";

import { Box } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export default function TablePopOver({ handleClick, anchorEl, tabs }) {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    handleClick(null);
  };

  return (
    <div>
      <Popper
        placement={"bottom-end"}
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledPopperDiv>
            {tabs.map((tab) => (
              <Box
                onClick={tab.onClick}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(208, 213, 221, 1)",
                    color: "white",
                  },
                  p: "6px 16px 6px 16px",
                }}
              >
                <tab.icon sx={{ pr: 1 }} />
                <div>{tab.label}</div>
              </Box>
            ))}
          </StyledPopperDiv>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

const StyledPopperDiv = styled("div")(
  ({ theme }) => css`
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(208, 213, 221, 1);
    box-shadow: 0px 2px 4px 0px rgba(117, 128, 146, 0.08);
    color: rgba(37, 122, 191, 1);
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    opacity: 1;
    size: 14px;
    margin: 0.25rem 0;
  `,
);
