import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuthCookies } from "./../context/CookieManager";

export const refreshAuthLogic = (client) => {
  return client.post("/auth/refresh-token", {
    refresh_token: getAuthCookies().refreshToken ?? "",
  });
};

export const createRefreshToken = (client) => {
  return createAuthRefreshInterceptor(client, () => refreshAuthLogic(client), {
    pauseInstanceWhileRefreshing: true,
    statusCodes: [401],
  });
};
