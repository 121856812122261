import {
  getAuthCookies,
  clearAuthCookies,
  setCookies,
} from "./../context/CookieManager";

const requestSuccessInterceptor = (req) => {
  if (req?.url === "/login") {
    return req;
  }
  const token = getAuthCookies().accessToken;
  req.headers.authorization = "Bearer " + token;
  return req;
};

const responseSuccessInterceptor = (res) => {
  if (res?.config?.url === "/auth/refresh-token") {
    //refresh token is set on hold
    // setCookies("accessToken", res.data.access_token);
    // setCookies("refreshToken", res.data.refresh_token);
    clearAuthCookies();
    window.location.href = "/";
  }
  return Promise.resolve(res);
};

const responseErrorInterceptor = (res) => {
  if (res?.config?.url === "/auth/refresh-token") {
    clearAuthCookies();
    window.location.href = "/";
    return Promise.reject(res);
  }
  return Promise.reject(res.response.data);
};

export {
  requestSuccessInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
};
