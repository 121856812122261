import React, { useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const AppTabPanel = ({ tabs, prefix }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const getCurrentTabIndex = () => {
    const index = tabs.findIndex((tab) => prefix + tab.path === currentPath);
    return index !== -1 ? index : 0; // Default to the first tab if path not found
  };

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(getCurrentTabIndex());
  }, [tabs]);

  return (
    <Box>
      <Tabs
        value={value}
        aria-label="custom tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
      >
        {tabs.map((tab, index) => (
          <Tab
            component="a"
            onClick={(e) => {
              e.preventDefault();
              navigate(prefix + tab.path);
              setValue(index);
            }}
            key={index}
            label={tab.label}
            href={prefix + tab.path}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default AppTabPanel;
